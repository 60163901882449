
/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
query SmsCountDeliveredQuery($customerId: ID, $vendorId: ID, $endDate: DateTime!, $startDate: DateTime!) {
  SmsCountDelivered(customerId: $customerId, vendorId: $vendorId, endDate: $endDate, startDate: $startDate){
      count,
      _totalCount,
      message,
      status
    }
  }
`

/*
 * EXPORTS
 */
export default Index
