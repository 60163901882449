/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import CustomerReadQuery from './__query__/index.customer.read.query'
import AccountUpdateMutation from './__mutation__/index.account.update.mutation'


/*
 * OBJECTS
 */
const Index = ({ onClose, passOn }) => {
  // Hook assignment.
  const [QueryCustomerRead, QueryCustomerReadResponse] = useLazyQuery(CustomerReadQuery)
  const [MutationAccountUpdate, MutationAccountUpdateResponse] = useMutation(AccountUpdateMutation)
  const [error, setError] = React.useState('')
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'password': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Fetch customer account login information.
    const _QueryCustomerRead = await QueryCustomerRead({ 'variables': { 'customerId': passOn?.customerId } })

    // If fetching customer account login information caught an exception then report failure.
    if (_QueryCustomerRead instanceof Error) return _QueryCustomerRead
    if ('READ_SUCCESSFUL' !== _.first(_QueryCustomerRead?.data?.CustomerRead)?.status) return toast(_.first(_QueryCustomerRead?.data?.CustomerRead)?.message)

    // Create CustomerAccount for given Customer.
    const _MutationAccountUpdate = await MutationAccountUpdate({
      'variables': {
        'accountId': _.first(_QueryCustomerRead?.data?.CustomerRead)?.AccountLogin?.id,
        'password': _formDataRef.current?.password
      }
    })

    // If creating or updating customer account caught an exception then report failure.
    if (_MutationAccountUpdate instanceof Error) return _MutationAccountUpdate

    // Style Guide.
    toast(_MutationAccountUpdate?.data?.AccountSetting?.message)

    // On Successful response from the mutation.
    if ('UPDATE_SUCCESSFUL' === _MutationAccountUpdate?.data?.AccountSetting?.status) onClose?.()

    // Return void 0.
    return void 0
  }

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm}>
      <MemoizedInput
        disabled={QueryCustomerReadResponse.loading || MutationAccountUpdateResponse.loading}
        isRequired={true}
        name='password'
        label='New Password'
        placeholder='e.g. "Super Secret"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        isInvalid={error?.includes('password')}
        error={error}
      />
      <SubmitButton
        isLoading={QueryCustomerReadResponse.loading || MutationAccountUpdateResponse.loading}
        defaultText='Update Password'
        disabled={QueryCustomerReadResponse.loading || MutationAccountUpdateResponse.loading}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
