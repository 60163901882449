/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($customerId: ID!) {
  CustomerAccountRead(customerId: $customerId) {
    id,
    createdAt,
    displayName,
    enableLossProtection,
    message,
    status,
    _totalCount
  }
}
`
