/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation SmsSendMutation(
  $customerAccountId: ID!,
  $destinationAddress: [String!],
  $message: [String!]!,
  $sourceAddress: String!,
  $scheduledDeliveryAt: DateTime,
  $repeatDeliveryAt: String,
  $validityPeriod: DateTime,
  $type: String!,
  $encoding: SMS_BUILT_TYPE!,
  $esmClass: Int,
  $priorityFlag: PositiveInt,
  $principalId: String,
  $templateId: String,
  $contactBookDirectoryId: ID,
  $contactBook: [ID]
) {
  SmsSend(
    customerAccountId: $customerAccountId,
    destinationAddress: $destinationAddress,
    message: $message,
    sourceAddress: $sourceAddress,
    type: $type,
    encoding: $encoding,
    esmClass: $esmClass,
    scheduledDeliveryAt: $scheduledDeliveryAt,
    repeatDeliveryAt: $repeatDeliveryAt,
    validityPeriod: $validityPeriod,
    priorityFlag: $priorityFlag,
    principalId: $principalId,
    templateId: $templateId,
    contactBookDirectoryId: $contactBookDirectoryId,
    contactBook: $contactBook
  ) {
    message
    status
  }
}
`
