/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import ContactBookReadUniqueQuery from './__query__/index.contactBook.read.query'
import ContactBookUpsertMutation from './__mutation__/index.contactBook.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryContactBookReadUnique, QueryContactBookReadUniqueResponse] = useLazyQuery(ContactBookReadUniqueQuery, { 'variables': { 'contactBookId': passOn?.contactBookId } })
  const [MutationContactBookUpsert, MutationContactBookUpsertResponse] = useMutation(ContactBookUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'contactBook': JoiBrowser.array().items(JoiBrowser.string().required()).required(),
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate({ ..._formDataRef.current, 'contactBook': _.isString(_formDataRef.current.contactBook) ? [_formDataRef.current.contactBook] : _formDataRef.current.contactBook })

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationContactBookUpsert = await MutationContactBookUpsert({
      'variables': {
        'contactBookDirectoryId': isCreateOnly ? passOn?.contactBookDirectoryId : void 0,
        'contactBook': _formDataRef?.current?.contactBook,
        'contactBookId': isCreateOnly ? void 0 : passOn?.contactBookId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationContactBookUpsert instanceof Error) return _MutationContactBookUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationContactBookUpsert?.data?.ContactBookUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationContactBookUpsert?.data?.ContactBookUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryContactBookReadUniqueQuery = await QueryContactBookReadUnique({ 'variables': { 'contactBookId': isCreateOnly ? 'UN_KNOWN' : passOn?.contactBookId } })

      // If query caught an exception then report failure.
      if (_QueryContactBookReadUniqueQuery instanceof Error) return _QueryContactBookReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryContactBookReadUniqueQuery?.data?.ContactBookReadUnique) {
        // Update form data.
        _formDataRef.current = {
          'contactBookId': passOn?.contactBookId,
          'contactBook': [_QueryContactBookReadUniqueQuery?.data?.ContactBookReadUnique?.contactBook]
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationContactBookUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryContactBookReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        isRequired={true}
        name='contactBook'
        label='Number'
        placeholder='e.g. "8826668515"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('contactBook')}
        data={_.first(_formDataRef?.current?.contactBook)}
      />
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Number' : 'Update Number'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
