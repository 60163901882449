/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RatePlanReadQuery($rateId: ID!, $take: PositiveInt, $skip: Int) {
    RatePlanRead(rateId: $rateId, take: $take, skip: $skip) {
      id
      createdAt
      updatedAt
      smsType,
      rate,
      message,
      status,
      _totalCount,
      Rate {
        id
        displayName,
        currency
      }
      Mcc {
        mcc,
        countryName
      },
      Mnc {
        mnc,
        network
      }
    }
  }
`
