/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation ContactBookDirectoryUpsertMutation($customerId: ID!, $contactBookDirectoryId: ID, $displayName: String) {
  ContactBookDirectoryUpsert(customerId: $customerId, contactBookDirectoryId: $contactBookDirectoryId, displayName: $displayName) {
    id
    message
    status
  }
}
`
