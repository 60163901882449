/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalHeader,
  ModalOverlay
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import { headerStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ title, isOpen, onClose, children, isCentered, size }) =>
// Return component.
(
  <Modal
    bg='blackAlpha.300'
    size={size}
    isOpen={isOpen}
    onClose={onClose}
    isCentered={isCentered}>
    {' '}
    <ModalOverlay />
    <ModalContent borderRadius='25px' pt='12px'>
      {title ? <ModalHeader style={headerStyle} pl='22px' pb={0}>{title}</ModalHeader> : void 0}
      <ModalCloseButton
        size='lg'
        mr='12px'
        mt='12px'
        borderRadius='12px'
        onClick={onClose}
        color='rgba(238, 93, 80, 1)'
        background='rgba(0, 0, 0, 0.08)'
        _focus={{ 'boxShadow': 'none' }}
      />
      <ModalBody borderRadius={8} p='22px'>
        {React.Children.map(children, child => {
          // Only clone the child if it's a valid React element
          if (React.isValidElement(children)) return React.cloneElement(children, { onClose })

          // Return child.
          return child
        })}
      </ModalBody>
    </ModalContent>
  </Modal>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'title': PropTypes.string,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'children': PropTypes.any,
  'size': PropTypes.string,
  'isCentered': PropTypes.bool
}
Index.defaultProps = {
  'size': 'xl',
  'isCentered': true
}


/*
 * EXPORT
 */
export default Index
