/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { TagsInput } from 'react-tag-input-component' // Npm: React tags input.
import { useDetectClickOutside } from 'react-detect-click-outside' // Npm: React detect click outside.
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const MemoizedInput = ({
  name,
  label,
  placeholder,
  containerStyle,
  type,
  data,
  color = '#2B3674',
  labelColor,
  bg = 'gray.100',
  onChange,
  error,
  icon,
  isRequired,
  isInvalid,
  disabled,
  isMultiple,
  ...props
}) => {
  // Hook assignment.
  const [multiRefValue, setMultiRefValue] = React.useState(data ?? [])
  const _isMultipleRef = React.useRef({})
  const _isMetaKeyPressed = React.useRef(void 0)

  // Return component.
  return (
    <Flex w='inherit' className='memoizedInput' direction='column'>
      <Flex w='inherit' style={containerStyle} direction='column'>
        <FormControl w='inherit' color={color} className={isInvalid ? 'inputInvalid' : disabled ? 'disabled' : void 0} isInvalid={isInvalid}>
          {
            label ? (
              <FormLabel color={labelColor}>
                {isRequired ? <Text display='flex' flexDirection='row'>{label}<Text color='red'>*</Text></Text> : <Text>{label}</Text>}
              </FormLabel>
            ) : void 0
          }
          {
            isMultiple ? (
              <Flex boxShadow={isInvalid ? '0 0 0 1.5px #EE5D50' : void 0} w='inherit' overflowY='auto' borderRadius='12px' opacity={disabled ? 0.5 : 1} bg={bg} color={color}>
                <TagsInput
                  ref={_isMultipleRef}
                  innerRef={_isMultipleRef}
                  style={{ 'border': 'none', 'minHeight': '40px' }}
                  placeHolder={placeholder}
                  value={multiRefValue}
                  name={name}
                  width='inherit'
                  onChange={i => onChange({ 'target': { name, 'value': i } })}
                  type={type}
                  backgroundColor={bg}
                  disabled={disabled}
                  onKeyUp={async (event) => {
                    // Save state if meta key is pressed.
                    if (event.key === 'Meta' || event.ctrlKey) {
                      // Update meta key state.
                      _isMetaKeyPressed.current = true
                    }

                    // Const assignment.
                    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

                    // Listen to paste event on given input.
                    if ((isMac && _isMetaKeyPressed.current && event.key === 'v') || (!isMac && _isMetaKeyPressed.current && event.key === 'v')) {
                      // Const assignment.
                      const _pastevalue = await navigator.clipboard.readText()

                      // Only proceed if pasteValue is available.
                      if (_.isEmpty(_pastevalue)) {
                        // Clear meta key state.
                        _isMetaKeyPressed.current = void 0

                        // Return void 0.
                        return void 0
                      }

                      // Clear meta key state.
                      _isMetaKeyPressed.current = void 0

                      // Update _isMultipleRef.
                      const _updatedValues = _.uniq([...data, ..._pastevalue?.split?.('\r\n')])

                      // Update multiref value.
                      setMultiRefValue(_updatedValues)

                      // call the onChange function.
                      onChange({ 'target': { 'name': name, 'value': _updatedValues } })
                    }
                  }}
                  allowAdditionFromPaste={true}
                  {...props}
                />
              </Flex>
            ) : (
              <Flex w='inherit' position='relative'>
                <Input
                  style={{ 'boxShadow': isInvalid ? '0 0 0 1.5px #EE5D50' : void 0, color, 'border': 'none' }}
                  placeholder={placeholder}
                  _placeholder={{ 'color': color ?? '#000' }}
                  defaultValue={data}
                  name={name}
                  w='inherit'
                  minH='40px'
                  borderRadius='12px'
                  onChange={onChange}
                  type={type}
                  bg={bg}
                  disabled={disabled}
                  {...props}
                />
                {icon ? icon : void 0}
              </Flex>
            )
          }
          {isInvalid && error ? (<FormErrorMessage>{error}</FormErrorMessage>) : void 0}
        </FormControl>
      </Flex>
    </Flex>
  )
}
const MemoizedSelect = ({
  name,
  label,
  disabled,
  containerStyle,
  color = '#2B3674',
  error,
  bg = 'gray.100',
  placeholder,
  labelColor,
  icon,
  onChange,
  isInvalid,
  isRequired,
  data,
  options = [],
  ...props
}) => (
  <Flex w='inherit' alignItems='center' className='memoizedInput' style={containerStyle} direction='column' borderRadius={12}>
    <FormControl position='relative' w='inherit' isRequired={isRequired} isInvalid={isInvalid}>
      {label ? <FormLabel w='inherit' display='flex' color={labelColor}><Text fontWeight={500}>{label}</Text></FormLabel> : void 0}
      <Flex w='inherit' position='relative'>
        <Select
          className={isInvalid ? 'inputInvalid' : void 0}
          style={{ 'boxShadow': isInvalid ? '0 0 0 1.5px #EE5D50' : void 0, 'border': 'none' }}
          defaultValue={data}
          border='none'
          outline={0}
          minH='40px'
          placeholder={placeholder}
          name={name}
          w='inherit'
          p={0}
          borderRadius='12px'
          bg={isInvalid ? 'white' : bg}
          disabled={disabled}
          onChange={onChange}
          _placeholder={{ color }}
          color={color}
          _focus={{ 'borderColor': 'none' }}
          _hover={{ bg }}
          sx={{
            '&': {
              'fontSize': '15px',
              'color': '' === data ? 'black' : 'current'
            },
            "& option[value='']": { 'fontSize': '15px', 'color': 'black' },
            "& :not(option[value=''])": { 'fontSize': '15px', 'color': 'black' }
          }}
          {...props}>
          {options.map((item, index) => (
            <option key={index}>{item}</option>
          ))}
        </Select>
        {icon ? icon : void 0}
      </Flex>
      {isInvalid && error ? (<FormErrorMessage>{error}</FormErrorMessage>) : void 0}
    </FormControl>
  </Flex>
)
const MemoizedSearchSelect = ({
  name,
  label,
  disabled,
  containerStyle,
  color = '#2B3674',
  error,
  placeholder,
  labelColor,
  onChange,
  onSelect,
  isInvalid,
  isRequired,
  data,
  bg = 'gray.100',
  options = [],
  ...props
}) => {
  // Hook assignment.
  const [selectedOption, setSelectedOption] = React.useState('')
  const [search, setSearch] = React.useState([])
  const [value, setValue] = React.useState(data)
  const _selectedOptionRef = useDetectClickOutside({ 'onTriggered': () => setSearch([]) })

  // Event handler.
  React.useEffect(() => {
    // Clear selected option on data change.
    setSelectedOption('')
  }, [value])
  React.useEffect(() => {
    // Update if data is available.
    setValue(data)
  }, [data])

  // Return component.
  return (
    <Flex w='inherit' className='memoizedInput' style={containerStyle} direction='column'>
      <FormControl w='inherit' isRequired={isRequired} color={color} isInvalid={isInvalid}>
        <FormLabel w='inherit' color={labelColor}>{label}</FormLabel>
        <Input
          style={{ 'boxShadow': isInvalid ? '0 0 0 1.5px #EE5D50' : void 0, 'border': 'none' }}
          placeholder={placeholder}
          _placeholder={{ color }}
          defaultValue={selectedOption}
          name={name}
          bg={bg}
          minH='40px'
          w='inherit'
          borderRadius='12px'
          disabled={disabled}
          value={_.isEmpty(selectedOption) ? value : selectedOption}
          onChange={i => {
            // Update value.
            setValue(i.target.value)

            // Update search.
            setSearch(options.filter(j => j.toLowerCase().startsWith(i.target.value.toLowerCase())))

            // Call the onChange function.
            onChange?.(i)
          }}
          {...props}
        />
        {
          !_.isEmpty(value) && 0 < search?.length ? (
            <Flex className='searchSelectDropDown' ref={_selectedOptionRef} flexDir='column' mt={2}>
              {
                search?.map((item, index) => (
                  <Flex w='inherit' key={index} onClick={item.onClick}>
                    <Button
                      fontSize='md'
                      height='100%'
                      minH='40px'
                      fontWeight={500}
                      bg='transparent'
                      color='gray.600'
                      justifyContent='start'
                      w='inherit'
                      borderRadius={0}
                      onClick={() => {
                        // Update selected option.
                        setSelectedOption(item)

                        // Update value.
                        setSearch([])

                        // Call all change with updates.
                        onSelect?.({ 'target': { 'value': item } })
                      }}
                      _hover={{ 'bg': 'gray.100', 'color': 'gray.500' }}
                      _active={{ 'bg': 'gray.100' }}>
                      {item}
                    </Button>
                  </Flex>
                ))
              }
            </Flex>
          ) : void 0
        }
      </FormControl>
      {isInvalid && error ? (<FormErrorMessage>{error}</FormErrorMessage>) : void 0}
    </Flex>
  )
}



/*
 * PROPTYPES
 */
MemoizedInput.propTypes = {
  'name': PropTypes.string,
  'label': PropTypes.string,
  'placeholder': PropTypes.string,
  'type': PropTypes.string,
  'data': PropTypes.any,
  'onChange': PropTypes.func,
  'isInvalid': PropTypes.bool,
  'error': PropTypes.string,
  'isRequired': PropTypes.bool,
  'disabled': PropTypes.bool,
  'containerStyle': PropTypes.object,
  'color': PropTypes.string,
  'isMultiple': PropTypes.bool,
  'bg': PropTypes.string,
  'icon': PropTypes.any,
  'labelColor': PropTypes.string
}
MemoizedSearchSelect.propTypes = {
  'name': PropTypes.string,
  'label': PropTypes.string,
  'placeholder': PropTypes.string,
  'onChange': PropTypes.func,
  'onSelect': PropTypes.func,
  'isInvalid': PropTypes.bool,
  'data': PropTypes.any,
  'options': PropTypes.array,
  'props': PropTypes.object,
  'isRequired': PropTypes.bool,
  'disabled': PropTypes.bool,
  'containerStyle': PropTypes.object,
  'color': PropTypes.string,
  'error': PropTypes.string,
  'bg': PropTypes.string,
  'labelColor': PropTypes.string
}
MemoizedSelect.propTypes = {
  'name': PropTypes.string,
  'label': PropTypes.string,
  'placeholder': PropTypes.string,
  'onChange': PropTypes.func,
  'isInvalid': PropTypes.bool,
  'data': PropTypes.any,
  'options': PropTypes.array,
  'props': PropTypes.object,
  'isRequired': PropTypes.bool,
  'disabled': PropTypes.bool,
  'containerStyle': PropTypes.object,
  'color': PropTypes.string,
  'error': PropTypes.string,
  'bg': PropTypes.string,
  'icon': PropTypes.any,
  'labelColor': PropTypes.string
}


/*
 * EXPORTS
 */
export { MemoizedInput, MemoizedSelect, MemoizedSearchSelect }
