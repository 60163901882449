/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Text,
  Textarea
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import DltReadUniqueQuery from './__query__/index.dlt.read.query'
import DltUpsertMutation from './__mutation__/index.dlt.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryDltReadUnique, QueryDltReadUniqueResponse] = useLazyQuery(DltReadUniqueQuery, { 'variables': { 'dltId': passOn?.dltId } })
  const [MutationDltUpsert, MutationDltUpsertResponse] = useMutation(DltUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'content': JoiBrowser.string().required(),
      'senderId': JoiBrowser.string().required(),
      'templateId': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationDltUpsert = await MutationDltUpsert({
      'variables': {
        'dltDirectoryId': isCreateOnly ? passOn?.dltDirectoryId : void 0,
        'dltId': isCreateOnly ? void 0 : passOn?.dltId,
        'displayName': _formDataRef?.current?.displayName,
        'content': _formDataRef?.current?.content,
        'templateId': _formDataRef?.current?.templateId,
        'senderId': _formDataRef?.current?.senderId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationDltUpsert instanceof Error) return _MutationDltUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationDltUpsert?.data?.DltUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationDltUpsert?.data?.DltUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryDltReadUniqueQuery = await QueryDltReadUnique({ 'variables': { 'dltId': isCreateOnly ? 'UN_KNOWN' : passOn?.dltId } })

      // If query caught an exception then report failure.
      if (_QueryDltReadUniqueQuery instanceof Error) return _QueryDltReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_QueryDltReadUniqueQuery?.data?.DltReadUnique) && _QueryDltReadUniqueQuery?.data?.DltReadUnique?.status === 'READ_SUCCESSFUL') {
        // Update form data.
        _formDataRef.current = {
          'dltId': passOn?.dltId,
          'displayName': _QueryDltReadUniqueQuery?.data?.DltReadUnique?.displayName,
          'content': _QueryDltReadUniqueQuery?.data?.DltReadUnique?.content,
          'templateId': _QueryDltReadUniqueQuery?.data?.DltReadUnique?.templateId,
          'senderId': _QueryDltReadUniqueQuery?.data?.DltReadUnique?.senderId
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationDltUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryDltReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex w='100%' flexDir='column' gap='22px'>
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='displayName'
            label='Display Name'
            placeholder='e.g. "ID, Number, Name"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('displayName')}
            data={_formDataRef?.current?.displayName}
            key={forceReRender}
            require={true}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='templateId'
            label='Template ID'
            placeholder='e.g. "XXX-XXX-XXX"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('templateId')}
            data={_formDataRef?.current?.templateId}
            require={true}
            key={forceReRender}
          />
          <MemoizedInput
            disabled={_isInputDisabled}
            isRequired={true}
            name='senderId'
            label='Sender ID'
            placeholder='e.g. "SPECIAL_6"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            isInvalid={error?.includes('senderId')}
            data={_formDataRef?.current?.senderId}
            key={forceReRender}
          />
        </Flex>
        <Flex flexDir='column' gap='6px'>
          <Text fontWeight={500}>Content<Text as='span' color='tomato'>*</Text></Text>
          <Textarea
            bg='gray.100'
            borderRadius='12px'
            disabled={_isInputDisabled}
            isRequired={true}
            type='textArea'
            name='content'
            resize='none'
            label='content'
            placeholder='e.g. "Your otp is {{var}}"'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: value
              }
            }}
            error={error}
            key={forceReRender}
            isInvalid={error?.includes('content')}
            defaultValue={_formDataRef?.current?.content}
          />
        </Flex>
      </Flex>
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Dlt' : 'Update Dlt'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
