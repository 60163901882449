/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation ContactBookDirectoryDeleteMutation($contactBookDirectoryId: ID!) {
  ContactBookDirectoryDelete(contactBookDirectoryId: $contactBookDirectoryId) {
    id
    message
    status
  }
}
`
