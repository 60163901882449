/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query DltDirectoryReadQuery($customerId: ID!, $dltDirectoryToSearch: String, $take: PositiveInt, $skip: Int) {
      DltDirectoryRead(customerId: $customerId, dltDirectoryToSearch: $dltDirectoryToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        displayName,
        Dlt {
          id,
          displayName,
          senderId,
          templateId
        }
        status,
        message,
        _totalCount
    }
  }
`
