/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import Moment from 'moment' // Npm: Moment.js library for date and time formatting.
import _ from 'underscore' // Npm: Underscore.js library for utility functions.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { IoMdAdd } from 'react-icons/io' // Npm: React icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client for GraphQL queries and mutations.
import {
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * SIBLINGS
 */
import DownloadToExcel from 'components/DownloadToExcel'
import TableSpinner from 'components/TableSpinner'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import Modal from 'components/Modal'
import RouteUpsert from 'components/RouteUpsert'
import RouteDelete from 'components/RouteDelete'
import RouteTerminal from 'components/RouteTerminal'
import RouteDropDownOptions from 'components/RouteDropDownOptions'


/*
 * GRAPHS
 */
import RouteReadQuery from './__query__/index.route.read.query'


/*
 * STYLES
 */
import { buttonStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ account }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [skipPage, setSkipPage] = React.useState(0)
  const [searchQuery, setSearchQuery] = React.useState('')
  const { 'isOpen': isRouteCreateFormOpen, 'onOpen': onRouteCreateFormOpen, 'onClose': onRouteCreateFormClose } = useDisclosure()
  const { 'isOpen': isRouteUpdateFormOpen, 'onOpen': onRouteUpdateFormOpen, 'onClose': onRouteUpdateFormClose } = useDisclosure()
  const { 'onOpen': onDeleteModalOpen, 'isOpen': isDeleteModalOpen, 'onClose': onDeleteModalClose } = useDisclosure()
  const { 'isOpen': isRouteTerminalOpen, 'onOpen': onRouteTerminalOpen, 'onClose': onRouteTerminalClose } = useDisclosure()
  const _QueryRouteRead = useQuery(RouteReadQuery, { 'variables': { 'accountId': account?.id, 'type': 'RETAIL', 'skip': skipPage * skipDifference, 'take': skipDifference, 'search': searchQuery }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onSearchInputChange = React.useCallback(Debounce(e => setSearchQuery(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data assignment.
  if (!_QueryRouteRead.loading && 0 < _QueryRouteRead.data?.RouteRead?.length) _isFirstLoadCompleted.current = true
  if (0 === _QueryRouteRead?.data?.RouteRead?.length || !_.every(_QueryRouteRead?.data?.RouteRead, j => _successFlags.includes(j.status)) || _.some(_QueryRouteRead?.data?.RouteRead, j => 'ROUTE_NOT_FOUND' === j.status)) _isFirstLoadCompleted.current = true

  // Return component.
  return (
    <>
      <Flex className='route base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='row'
          justifyContent='space-between'
          bg='linear-Gradient(73deg,#390EF6 0%, #9244F4 100%)'
          height='100%'
          borderTopLeftRadius='20px'
          borderTopRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Tooltip label='Create new route.' fontSize='sm'>
            <Button
              w='170px'
              onClick={onRouteCreateFormOpen}
              leftIcon={<IoMdAdd />}
              style={buttonStyle}>
              New Route
            </Button>
          </Tooltip>
          <DownloadToExcel
            cellsData={
              0 === _QueryRouteRead?.data?.RouteRead?.length || !_.every(
                _.pluck(_QueryRouteRead?.data?.RouteRead, 'status'),
                i => _successFlags?.includes(i)
              ) ? [] : _QueryRouteRead?.data?.RouteRead?.map((item, __index) => ({
                'S.No.': `${__index + (skipDifference * skipPage) + 1}.`,
                'Route Name': item?.displayName ?? '-',
                'Route Type': item?.type ?? '-',
                'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD'),
                'Created By': item?.Account?.displayName ?? '-'
              }))
            }
            headersData={[
              'S.No.',
              'Route Name',
              'Route Type',
              'CreatedAt',
              'UpdatedAt',
              'Created By'
            ].map(i => ({ 'key': i, 'label': i }))}
          />
        </Flex>
        <Tabs
          variant='enclosed'
          display='flex'
          flexDirection='column'
          align='center'
          w='100%'
          h='100%'
          bg='white'
          borderBottomLeftRadius='20px'
          borderBottomRightRadius='20px'
          isFitted>
          <Flex h='100%'>
            <TableContainer
              display='flex'
              border='2px solid #3A0FF6'
              borderBottomRadius='20px'>
              <Table
                __css={{
                  'tableLayout': { 'base': 'auto', 'md': 'fixed' },
                  'width': { 'base': 'auto', 'md': 'full' }
                }}
                variant='simple'
                size='sm'>
                <Thead ref={_tableHeaderHeightRef}>
                  <Tr
                    style={{
                      ...headerStyle,
                      'top': '0',
                      'zIndex': '1',
                      'position': 'sticky'
                    }}>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      S No.
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      Route Name
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      Route Type
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      CreatedAt
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      UpdatedAt
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      Created By
                    </Td>
                    <Td
                      style={rowStyle}>
                      Action
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'>
                      <Input
                        placeholder='Search'
                        className='filter searchInput'
                        bg='gray.100'
                        onChange={_onSearchInputChange}
                      />
                    </Td>
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                      borderRight='1px solid rgba(216, 227, 252, 1)'
                    />
                    <Td
                      style={rowStyle}
                    />
                  </Tr>
                </Thead>
                {_QueryRouteRead.loading && !_isFirstLoadCompleted.current ? (
                  <TableSpinner
                    isLoading={true}
                    chopHeightFromHundredPercentage={
                      _tableHeaderHeightRef?.current?.clientHeight
                    }
                  />
                ) : 0 === _QueryRouteRead?.data?.RouteRead?.length || !_.every(_QueryRouteRead?.data?.RouteRead, j =>
                  _successFlags.includes(j.status)) || _.some(
                    _QueryRouteRead?.data?.RouteRead,
                    j => 'ROUTE_NOT_FOUND' === j.status
                  ) ? (
                  <TableSpinner
                    isLoading={false}
                    isEmpty={true}
                    chopHeightFromHundredPercentage={
                      _tableHeaderHeightRef?.current?.clientHeight
                    }
                  />
                ) : (
                  <Tbody>
                    {_QueryRouteRead?.data?.RouteRead?.map?.((item, index) => (
                      <Tr key={index} textAlign='center'>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {`${index + (skipDifference * skipPage) + 1}.`}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {item?.displayName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'
                        >
                          {item?.type ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(item.createdAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {Moment(item.updatedAt).format('YYYY-MM-DD')}
                        </Td>
                        <Td
                          style={rowStyle}
                          borderRight='1px solid rgba(216, 227, 252, 1)'>
                          {item?.Account?.displayName ?? '-'}
                        </Td>
                        <Td
                          style={rowStyle}>
                          <RouteDropDownOptions
                            type={item.type}
                            routeId={item.id}
                            onRouteDelete={onDeleteModalOpen}
                            onRouteUpdate={onRouteUpdateFormOpen}
                            onRouteTerminalOpen={onRouteTerminalOpen}
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </Flex>
        </Tabs>
        <Modal
          size='md'
          title='Route Create'
          isOpen={isRouteCreateFormOpen}
          onClose={onRouteCreateFormClose}>
          <RouteUpsert type='RETAIL' isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Route Update'
          isOpen={isRouteUpdateFormOpen}
          onClose={onRouteUpdateFormClose}>
          <RouteUpsert type='RETAIL' />
        </Modal>
        <Modal
          size='md'
          title='Route Delete'
          isOpen={isDeleteModalOpen}
          onClose={onDeleteModalClose}>
          <RouteDelete />
        </Modal>
        <Modal
          size='3xl'
          title='Route Terminal'
          isOpen={isRouteTerminalOpen}
          onClose={onRouteTerminalClose}
          isCentered={false}>
          <RouteTerminal />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryRouteRead.data?.RouteRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
