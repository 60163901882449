/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerReadQuery($customerId: ID, $take: PositiveInt, $skip: Int) {
  CustomerRead(customerId: $customerId, take: $take, skip: $skip) {
    status,
    CustomerAccount {
      Rate {
          id,
          displayName
      }
      Route {
          id,
          displayName
      }
    }
  }
}
`
