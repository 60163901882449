/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop-types library.
import * as XLSX from 'xlsx' // Npm: XLSX library.
import _ from 'underscore' // Npm: Underscore.js library.
import { HiArrowUpCircle, HiMinusCircle } from 'react-icons/hi2' // Npm: react icons library.
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
  Spinner,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({ bg, color, columnEndPosition = 1, onChangeWithFileReader, clearIconColor, label, uploadingFile, onChange, onFileUpload, onClear, disabled, isLoading, ...props }) => {
  // Hook assignment.
  const [forceReRender, setForceReRender] = React.useState(void 0)
  const [filename, setFilename] = React.useState(void 0)
  const _fileRef = React.useRef({})
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Object assignment.
  const _onChange = e => Promise.all(Array.from(e.target.files).map(file => {
    // Const assignment.
    const _FileReader = new FileReader()

    // Read file as binary string.
    _FileReader.readAsBinaryString(file)

    // Event handler.
    _FileReader.onload = () => {
      // Const assignment.
      const _data = _FileReader.result

      // Const assignment.
      const _raw = _data.split('\r\n')

      // Set filename.
      setFilename(file.name)

      // Create row by mapping through _raw data.
      const _rows = Array.from({ length: _raw.length }, (v, i) => _raw[i].split(','));

      // Execute mutation.
      return onChange?.({ 'columns': _rows[0], 'rows': _rows.slice(1, _raw.length) })
    }
  }))
  const _onChangeWithFileReader = e => {
    // Update file name.
    setFilename(e.target.files[0].name)

    // Execute mutation.
    return onChangeWithFileReader?.(e)
  }

  // Return component.
  return (
    <FormControl cursor='pointer' w='inherit' disabled={disabled} opacity={disabled ? 0.5 : 1} h='40px' bg={_.isEmpty(filename) ? (bg ?? 'brand.100') : 'red.100'} color={_.isEmpty(filename) ? (color ?? 'brand.600') : 'tomato'} borderRadius='12px' borderWidth={0} overflow='hidden'>
      <FormLabel h='100%' display='flex' alignItems='center' ml='18px' mr='16px' htmlFor='file' _hover={{ 'bg': 'transparent' }} flexDir='row' gap='6px'>{_isCurrentViewMobile ? void 0 : (<Text as='h4' cursor='pointer'>{filename ?? label ?? 'e.g. "Awesome.csv"'}</Text>)}{uploadingFile || isLoading ? (<Spinner size='sm' />) : _.isEmpty(filename) ? (<HiArrowUpCircle size={20} />) : (<Box color={clearIconColor ?? 'tomato'} position='relative' zIndex={10} onClick={() => { _fileRef.current.target && (_fileRef.current.value = void 0); onChange?.([]); setFilename(void 0); setForceReRender(String.random(9)); onClear?.({}) }}><HiMinusCircle size={21} /></Box>)}</FormLabel>
      <Box position='absolute' top='0' left='0' width='inherit' height='100%' opacity={0}>
        <Input
          ref={_fileRef}
          type='file'
          cursor='pointer'
          border='none'
          opacity={disabled ? 0.5 : 1}
          disabled={disabled}
          w={{ 'base': '100%', 'md': 'auto' }}
          sx={{
            '::file-selector-button': {
              'height': 10,
              'padding': 0,
              'mr': 4,
              'fontWeight': '400',
              'background': 'none',
              'border': 'none',
              'color': 'purple'
            }
          }}
          _focus={{
            'border': 'none'
          }}
          _hover={{
            'border': 'none'
          }}
          {...props}
          key={forceReRender}
          onChange={onChangeWithFileReader ? _onChangeWithFileReader : _onChange}
        />
      </Box>
    </FormControl>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'bg': PropTypes.string,
  'color': PropTypes.string,
  'label': PropTypes.string,
  'uploadingFile': PropTypes.bool,
  'onChange': PropTypes.func,
  'onClear': PropTypes.func,
  'disabled': PropTypes.bool,
  'isLoading': PropTypes.bool
}


/*
 * EXPORTS
 */
export default Index
