/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    query ContactBookReadQuery($contactBookDirectoryId: ID, $contactBookToSearch: String, $take: PositiveInt, $skip: Int) {
      ContactBookRead(contactBookDirectoryId: $contactBookDirectoryId, contactBookToSearch: $contactBookToSearch, take: $take, skip: $skip) {
        id,
        createdAt,
        updatedAt,
        contactBook,
        status,
        message,
        _totalCount
    }
  }
`
