/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query ContactBookDirectoryReadQuery($customerId: ID!, $take: PositiveInt!, $skip: Int!) {
    ContactBookDirectoryRead(customerId: $customerId, take: $take, skip: $skip) {
      id,
      displayName,
      message,
      status,
      ContactBook {
        id,
        contactBook
      }
    }
  }
`
