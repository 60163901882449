/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation, useQuery } from '@apollo/client' // Npm: Apollo client.
import { Flex, FormControl } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput, MemoizedSearchSelect, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RateReadQuery from './__query__/index.rate.read.query'
import RouteReadQuery from './__query__/index.route.read.query'
import CustomerAccountQuery from './__query__/index.customerAccount.read.query'
import CustomerAccountAttachRateMutation from './__mutation__/index.customerAccount.attachRate.mutation'
import CustomerAccountAttachSmppMutation from './__mutation__/index.customerAccount.attachSmpp.mutation'
import CustomerAccountUpdateMutation from './__mutation__/index.customerAccount.update.mutation'
import CustomerAccountCreateMutation from './__mutation__/index.customerAccount.create.mutation'
import CustomerAccountAttachRouteMutation from './__mutation__/index.customerAccount.attachRoute.mutation'
import SmppUpdateMutation from './__mutation__/index.smpp.update.mutation'
import SmppCreateMutation from './__mutation__/index.smpp.create.mutation'


/*
 * OBJECTS
 */
const Index = ({
  isCreateOnly,
  isOpen,
  onClose,
  passOn
}) => {
  // Hook assignment.
  const [rate, setRate] = React.useState('')
  const [route, setRoute] = React.useState('')
  const [MutationCustomerAccountAttachRoute, MutationCustomerAccountAttachRouteResponse] = useMutation(CustomerAccountAttachRouteMutation)
  const [MutationCustomerAccountAttachRate, MutationCustomerAccountAttachRateResponse] = useMutation(CustomerAccountAttachRateMutation)
  const [MutationCustomerAccountCreate, MutationCustomerAccountCreateResponse] = useMutation(CustomerAccountCreateMutation)
  const [MutationCustomerAccountUpdate, MutationCustomerAccountUpdateResponse] = useMutation(CustomerAccountUpdateMutation)
  const [QueryCustomerAccountRead, QueryCustomerAccountReadResponse] = useLazyQuery(CustomerAccountQuery)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [MutationSmppCreate, MutationSmppCreateResponse] = useMutation(SmppCreateMutation)
  const [MutationSmppUpdate, MutationSmppUpdateResponse] = useMutation(SmppUpdateMutation)
  const [MutationCustomerAccountAttachSmpp] = useMutation(CustomerAccountAttachSmppMutation)
  const _RateReadQuery = useQuery(RateReadQuery, { 'variables': { 'type': passOn?.type, 'take': 1000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _RouteReadQuery = useQuery(RouteReadQuery, { 'variables': { 'type': passOn?.type, 'take': 1000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Local variable.
    let _MutationCustomerAccountAttachRateToCustomer, _MutationCustomerAccountAttachRouteToCustomer

    // Prevent default behavior.
    e?.preventDefault()

    // Reset error.
    setError('')

    // Object assignment.
    const _CustomerAccountAttachRouteAndRate = async __customerAccountId => {
      // Const assignment.
      const _route = route?.includes?.('(') && route?.includes?.(')') ? route?.split('(')[1]?.split(')')[0] : route
      const _rate = rate?.includes?.('(') && rate?.includes?.(')') ? rate?.split('(')[1]?.split(')')[0] : rate

      // Only Execute if route has data.
      if (!_.isEmpty(_route)) {
        // Execute mutation.
        _MutationCustomerAccountAttachRouteToCustomer = await MutationCustomerAccountAttachRoute({
          'variables': {
            'customerAccountId': __customerAccountId ?? passOn?.customerAccountId,
            'routeId': _route
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationCustomerAccountAttachRouteToCustomer instanceof Error) throw _MutationCustomerAccountAttachRouteToCustomer
      }

      // Only proceed if rate has data.
      if (!_.isEmpty(_rate)) {
        // Bind Rate to given customer.
        _MutationCustomerAccountAttachRateToCustomer = await MutationCustomerAccountAttachRate({
          'variables': {
            'customerAccountId': __customerAccountId ?? passOn?.customerAccountId,
            'rateId': _rate
          }
        })

        // If mutation caught an exception then report failure.
        if (_MutationCustomerAccountAttachRateToCustomer instanceof Error) throw _MutationCustomerAccountAttachRateToCustomer
      }

      // Style Guide.
      (!_.isEmpty(_route) || !_.isEmpty(_rate)) && toast(_MutationCustomerAccountAttachRateToCustomer?.data?.CustomerAccountAttachRate?.message ?? _MutationCustomerAccountAttachRouteToCustomer?.data?.CustomerAccountAttachRoute?.message)
    }

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'ip': JoiBrowser.string().max(45).ip({ 'version': ['ipv4', 'ipv6', 'ipvfuture'], 'cidr': 'optional' }).required(),
      'username': JoiBrowser.string().required(),
      'password': JoiBrowser.string().required(),
      'sessionTimeoutInterval': JoiBrowser.number().required(),
      'sessionAllowed': JoiBrowser.number().optional(),
      'tps': JoiBrowser.number().optional(),
      'enquireLinkInterval': JoiBrowser.number().required(),
      'trunkType': JoiBrowser.string().required(),
      'debuggingLevel': JoiBrowser.any().required(),
      'rate': JoiBrowser.string().optional(),
      'routePlan': JoiBrowser.string().optional(),
      'enableLossProtection': JoiBrowser.boolean().optional()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Create CustomerAccount for given Customer.
    const _MutationCustomerAccount = await [isCreateOnly ? MutationCustomerAccountCreate : MutationCustomerAccountUpdate]?.[0]({
      'variables': {
        [isCreateOnly ? 'customerId' : 'customerAccountId']: isCreateOnly ? passOn?.customerId : passOn?.customerAccountId,
        'displayName': _formDataRef?.current?.displayName,
        'enableLossProtection': _formDataRef?.current?.enableLossProtection
      }
    })

    // If creating or updating customer account caught an exception then report failure.
    if (_MutationCustomerAccount instanceof Error) return _MutationCustomerAccount

    // Execute update mutation.
    const _MutationSmppUpdate = await [isCreateOnly ? MutationSmppCreate : MutationSmppUpdate]?.[0]({
      'variables': {
        ..._.omit(_formDataRef?.current, isCreateOnly ? 'smppId' : void 0, 'enableLossProtection'),
        'smppId': isCreateOnly ? void 0 : _formDataRef.current.smppId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationSmppUpdate instanceof Error) return _MutationSmppUpdate

    // Style Guide.
    toast(_MutationSmppUpdate?.data?.SmppCreate?.message ?? _MutationSmppUpdate?.data?.SmppUpdate?.message)

    // Attach route and rate to given customer.
    await _CustomerAccountAttachRouteAndRate(_MutationCustomerAccount?.data[isCreateOnly ? 'CustomerAccountCreate' : 'CustomerAccountUpdate']?.id)

    /*
     * Only execute attach query if its about
     * creation of new smpp.
     */
    if (isCreateOnly && 'CREATE_SUCCESSFUL' === _MutationSmppUpdate?.data?.SmppCreate?.status) {
      // Attach given customer with smpp.
      const _MutationCustomerAccountAttachSmpp = await MutationCustomerAccountAttachSmpp({ 'variables': { 'customerAccountId': _MutationCustomerAccount?.data[isCreateOnly ? 'CustomerAccountCreate' : 'CustomerAccountUpdate']?.id, 'smppId': _MutationSmppUpdate?.data?.SmppCreate?.id } })

      // If mutation caught an exception then report failure.
      if (_MutationCustomerAccountAttachSmpp instanceof Error) return _MutationCustomerAccountAttachSmpp

      // Only close if attach is successful.
      if ('UPDATE_SUCCESSFUL' === _MutationCustomerAccountAttachSmpp?.data?.CustomerAccountAttachSmpp?.status) return onClose?.()
    }
    if ('UPDATE_SUCCESSFUL' === _MutationSmppUpdate?.data?.SmppUpdate?.status) return onClose?.()


    // Return void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryCustomerAccountReadQuery = await QueryCustomerAccountRead({ 'variables': { 'customerAccountId': isCreateOnly ? 'UN_KNOWN' : passOn?.customerAccountId } })

      // If query caught an exception then report failure.
      if (_QueryCustomerAccountReadQuery instanceof Error) return _QueryCustomerAccountReadQuery

      /*
       * If details fetch complete then
       * update its value.
       */
      if (_.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead) && !_.isEmpty(_.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp)) {
        // Update form data.
        _formDataRef.current = {
          'smppId': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.id,
          'displayName': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.displayName,
          'ip': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.ip,
          'username': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.username,
          'password': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.password,
          'sessionTimeoutInterval': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.sessionTimeoutInterval,
          'sessionAllowed': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.sessionAllowed,
          'tps': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.tps,
          'enquireLinkInterval': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.enquireLinkInterval,
          'trunkType': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.trunkType,
          'debuggingLevel': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Smpp.debuggingLevel,
          'rate': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Rate?.displayName,
          'route': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead).Route?.displayName,
          'enableLossProtection': _.first(_QueryCustomerAccountReadQuery?.data?.CustomerAccountRead)?.enableLossProtection
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationCustomerAccountCreateResponse.loading || MutationCustomerAccountUpdateResponse.loading || MutationCustomerAccountAttachRateResponse.loading || MutationCustomerAccountAttachRouteResponse.loading || MutationSmppCreateResponse.loading || MutationSmppUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || _RouteReadQuery?.loading || _RateReadQuery?.loading || QueryCustomerAccountReadResponse?.loading


  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender} className='customerAccountUpsert'>
      <Flex gap='22px' flexDir='column' w='100%'>
        <Flex gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              isRequired={true}
              name='displayName'
              label='Display name'
              placeholder='e.g. "Rocking Smpp"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isInvalid={error?.includes('displayName')}
              error={error}
              data={_formDataRef?.current?.displayName}
            />
          </FormControl>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              name='ip'
              label='ip'
              placeholder='e.g. "0.0.0.0"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isRequired={true}
              error={error}
              isInvalid={error?.includes('ip')}
              data={_formDataRef?.current?.ip}
            />
          </FormControl>
        </Flex>
        <Flex gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              name='username'
              label='SystemId'
              placeholder='e.g. "RockSmpp"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isRequired={true}
              error={error}
              isInvalid={error?.includes('username')}
              data={_formDataRef?.current?.username}
            />
          </FormControl>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              name='password'
              label='Password'
              placeholder='e.g. "*******"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              isRequired={true}
              error={error}
              isInvalid={error?.includes('password')}
              data={_formDataRef?.current?.password}
            />
          </FormControl>
        </Flex>
        <Flex gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              isRequired={true}
              name='sessionTimeoutInterval'
              label='Response Time ( in Seconds )'
              placeholder='e.g. "3 is 3second"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Make sure that value is in seconds.
                if (1 > Number(value)) return setError('sessionTimeoutInterval must be greater than 0')

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: Number(value ?? 0) * 1000
                }

                // Return void.
                return void 0
              }}
              error={error}
              isInvalid={error?.includes('sessionTimeoutInterval')}
              data={_.isNumber(_formDataRef?.current?.enquireLinkInterval) && 0 < _formDataRef?.current?.sessionTimeoutInterval ? _formDataRef?.current?.sessionTimeoutInterval / 1000 : _formDataRef?.current?.sessionTimeoutInterval}
            />
          </FormControl>
          <FormControl>
            <MemoizedSelect
              disabled={_isInputDisabled}
              name='sessionAllowed'
              label='Session Allowed'
              placeholder='e.g. "2"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: Number(value ?? 0)
                }
              }}
              options={[
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10
              ]}
              error={error}
              isInvalid={error?.includes('sessionAllowed')}
              data={_formDataRef?.current?.sessionAllowed}
            />
          </FormControl>
        </Flex>
        <Flex gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              name='tps'
              label='Submit Per Second'
              placeholder='e.g. "1000 is 1000 Submit Per Second"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: 1000 < Number(value ?? 0) ? 1000 : Number(value ?? 0)
                }
              }}
              error={error}
              isInvalid={error?.includes('tps')}
              data={_formDataRef?.current?.tps}
            />
          </FormControl>
          <FormControl>
            <MemoizedInput
              disabled={_isInputDisabled}
              isRequired={true}
              name='enquireLinkInterval'
              label='Enquire link ( in Seconds )'
              placeholder='e.g. "60 is 60Seconds"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Make sure that value is in seconds.
                if (1 > Number(value)) return setError('enquireLinkInterval must be greater than 0')

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: Number(value ?? 0) * 1000
                }

                // Return void.
                return void 0
              }}
              error={error}
              isInvalid={error?.includes('enquireLinkInterval')}
              data={_.isNumber(_formDataRef?.current?.enquireLinkInterval) && 0 < _formDataRef?.current?.enquireLinkInterval ? _formDataRef?.current?.enquireLinkInterval / 1000 : _formDataRef?.current?.enquireLinkInterval}
            />
          </FormControl>
        </Flex>
        <Flex gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <FormControl>
            <MemoizedSelect
              disabled={_isInputDisabled}
              isRequired={true}
              name='trunkType'
              label='Route'
              placeholder='Select Route'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              options={Object.React.App.enums.TRUNK_TYPE.enums?.map(i => i.key)}
              isInvalid={error?.includes('trunkType')}
              data={_formDataRef?.current?.trunkType}
            />
          </FormControl>
          <FormControl>
            <MemoizedSelect
              disabled={_isInputDisabled}
              isRequired={true}
              name='debuggingLevel'
              label='Error Code Level'
              placeholder='Select Debugging level'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              options={Object.React.App.enums.SMPP_DEBUGGING_LEVEL.enums?.map(i => i.key)}
              isInvalid={error?.includes('debuggingLevel')}
              data={_formDataRef?.current?.debuggingLevel}
            />
          </FormControl>
        </Flex>
        <Flex gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
          <FormControl>
            <MemoizedSearchSelect
              disabled={_isInputDisabled}
              name='rate'
              label='Assign Rate'
              data={_formDataRef?.current?.rate ?? rate}
              placeholder='e.g. "Awesome Rate"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }

                // Update rate.
                setRate(value)
              }}
              options={_RateReadQuery?.data?.RateRead?.map(({ id, displayName }) => `${displayName} (${id})`)}
            />
          </FormControl>
          <FormControl>
            <MemoizedSearchSelect
              disabled={_isInputDisabled}
              label='Assign Route'
              name='route'
              data={_formDataRef?.current?.route}
              placeholder='e.g. "Amazing Route"'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }

                // Update route.
                setRoute(value)
              }}
              options={_RouteReadQuery?.data?.RouteRead?.map?.(({ id, displayName }) => `${displayName} (${id})`)}
            />
          </FormControl>
        </Flex>
      </Flex>
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Smpp' : 'Update Smpp'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object,
  'onClose': PropTypes.func,
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool
}
Index.defaultProps = {}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
