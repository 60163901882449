/*
 * IMPORTS
 */
import 'react-toastify/dist/ReactToastify.css' // Npm: React.js toastify library.
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import _ from 'lodash' // Npm: Lodash utility library.
import { useNavigate } from 'react-router-dom' // Npm: react router dom library.
import { connect } from 'react-redux' // Npm: Store management library.
import { useMutation, useQuery } from '@apollo/client' // Npm: Apollo Client library.
import { MdOutlineRemoveRedEye } from 'react-icons/md' // Npm: React icons library.
import { RiEyeCloseLine } from 'react-icons/ri' // Npm: React icons library.
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text
} from '@chakra-ui/react'// Npm: Chakra UI library.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import AccountSignInWithEmailMutation from './__mutation__/index.account.loginWithMutation'
import PlatformReadQuery from './__query__/index.platform.read.query'


/*
 * OBJECTS
 */
const Index = ({ AccountUpdate, CustomerUpdate, VendorUpdate, PlatformUpdate }) => {
  // Hook assignment.
  const [password, setPassword] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [error, setError] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [show, setShow] = React.useState(false)
  const [otp, setOtp] = React.useState('')
  const [showOtpInput, setShowOtpInput] = React.useState(false)
  const [MutationAccountLoginWithEmail] = useMutation(AccountSignInWithEmailMutation, { 'context': { 'headers': { 'l-authorization': '' } } })
  const _useNavigateRef = useNavigate()
  const _PlatformReadQuery = useQuery(PlatformReadQuery, { 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Object assignment.
  const _OnEmailChange = e => {
    // Update states.
    setError({})

    // Update password.
    setEmail(e.target.value)
  }
  const _OnOtpChange = e => {
    // Update states.
    setError({})

    // Update password.
    setOtp(e.target.value)
  }
  const _OnPasswordChange = e => {
    // Update states.
    setError({})

    // Update password.
    setPassword(e.target.value)
  }
  const _FormValidation = () => {
    // Const assignment.
    const errors = {}

    // Validate email
    if (!email.trim()) {
      // Make sure email is valid.
      errors.email = 'Email is required'
    } else if (!(/\S+@\S+\.\S+/u).test(email)) {
      // Validate if email is invalid.
      errors.email = 'Email is invalid'
    }

    // Validate password
    if (!password.trim()) {
      // Make sure password is passed.
      errors.password = 'Password is required'
    } else if (6 > password.length) {
      // Validate password length.
      errors.password = 'Password should be at least 6 characters long'
    }

    // Update error state.
    setError(errors)

    // Return true if there are no errors
    return 0 === Object.keys(errors).length
  }
  const _AccountLogin = async e => {
    // Error handling.
    try {
      // Prevent bubbling up event.
      e.preventDefault()

      // Check if form details are valid.
      if (!_FormValidation()) return void 0

      // Update loading state.
      setLoading(true)

      // Execute login mutation.
      const _MutationAccountLoginWithEmail = await MutationAccountLoginWithEmail({ 'variables': { 'email': email, 'password': password } })

      // If login caught exception than report failure.
      if (_MutationAccountLoginWithEmail instanceof Error) return _MutationAccountLoginWithEmail

      // On Successful login save user information.
      if (!_.isEmpty(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.status) && _.isString(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.status) && 'READ_SUCCESSFUL' === _MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.status) {
        // Update form with 2FA if it is enabled.
        if (_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.is2FAEnabled) {
          // If otp is not provided then ask for otp.
          if (_.isEmpty(otp)) {
            // Update error.
            setShowOtpInput(true)

            // Set loading state to false.
            return setLoading(false)
          }

          // Verify 2FA.
          const _MutationAccountTwoFactorAuthenticationCreate = await MutationAccountTwoFactorAuthenticationCreate({ 'variables': { 'email': email, 'token': otp } })

          // If 2FA verification failed then report failure.
          if ('2FA_VERIFIED' !== _MutationAccountTwoFactorAuthenticationCreate?.data?.AccountTwoFactorAuthenticationCreate?.status) {
            // Update error.
            setError({ 'otp': 'Otp is incorrect' })

            // Set loading state to false.
            return setLoading(false)
          }
        }

        /*
         * Only proceed if customer login is present else report
         * failure.
         */
        if (_.isEmpty(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.CustomerLogin) && _.isEmpty(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.VendorLogin)) {
          // Update error.
          setError({ 'email': 'Email or password is incorrect', 'password': 'Email or password is incorrect' })

          // Set loading state to false.
          return setLoading(false)
        }

        // Const assignment.
        const _customerLogin = _.first(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.CustomerLogin)
        const _vendorLogin = _.first(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.VendorLogin)

        // Only grab user details if customer id is present.
        if (!_.isEmpty(_customerLogin)) {
          // Update System with new updates
          await AccountUpdate({ ..._MutationAccountLoginWithEmail.data.AccountLoginWithEmail, 'isUserLoggedIn': true, 'CustomerLogin': _customerLogin })
          await CustomerUpdate(_customerLogin)
        } else if (!_.isEmpty(_vendorLogin)) {
          // Update cache with vendor login.
          await AccountUpdate({ ..._MutationAccountLoginWithEmail.data.AccountLoginWithEmail, 'isUserLoggedIn': true, 'VendorLogin': _vendorLogin })
          await VendorUpdate(_vendorLogin)
        }

        // Update System with new updates
        await AccountUpdate({ ..._MutationAccountLoginWithEmail.data.AccountLoginWithEmail, 'isUserLoggedIn': true })

        // Update loading state.
        setLoading(false)

        // Navigate to home page.
        return _useNavigateRef('/')
      } else if (!_.isEmpty(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.status) && _.isString(_MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.status) && 'ACCOUNT_IS_BLOCKED' === _MutationAccountLoginWithEmail?.data?.AccountLoginWithEmail?.status) {
        // Update error.
        setError({ 'email': 'Account is blocked. Please contact admin', 'password': 'Account is blocked. Please contact admin' })

        // Set loading state to false.
        return setLoading(false)
      }

      // Update error.
      setError({ 'email': 'Email or password is incorrect', 'password': 'Email or password is incorrect' })

      // Set loading state to false.
      return setLoading(false)
    } finally {
      // Set loading state to false.
      setLoading(false)
    }
  }

  // Event handler.
  React.useEffect(() => {
    // Only proceed if platform details are available.
    if (!_.isEmpty(_PlatformReadQuery?.data?.PlatformRead)) PlatformUpdate(_.first(_PlatformReadQuery?.data?.PlatformRead))
  }, [_PlatformReadQuery])

  // Return component.
  return (
    <Box h='100%' w='100%' overflow='hidden' justifyContent='center' alignItems='center' display='flex' bg='#0b1437'>
      <Flex
        zIndex={1000}
        maxW={{ 'base': '100%' }}
        w='max-content'
        m='auto'
        mx={{ 'base': 'auto', 'lg': '0px' }}
        me='auto'
        justifyContent='center'
        px={{ 'base': '20px', 'md': '0px' }}
        flexDirection='column'>
        <Box me='auto' w='100%'>
          <Box width='155px' height='70px' m='auto'>
            <svg fill='none' xmlns='http://www.w3.org/2000/svg'>
              <circle cx='77.2852' cy='25.3189' r='21.0299' fill='url(#paint0_linear_1306_1279)' />
              <path d='M75.6348 50.3874L78.3563 31.9141L82.1499 34.5531L75.6348 50.3874Z' fill='#FFD338' />
              <path d='M89.4893 0H74.6447L64.3359 25.1534L78.9331 19.9578L89.4893 0Z' fill='#FFD338' />
              <path d='M76.6241 31.7528L72.8305 29.0312L72.6655 34.1444L76.6241 31.7528Z' fill='#A7C6DA'
                stroke='#A7C6DA' strokeWidth='0.16494' />
              <path
                d='M83.5034 34.4385L87.4153 17.4868C87.5627 16.848 86.9418 16.3009 86.3266 16.5275L64.4374 24.592C63.8538 24.8069 63.8646 25.636 64.4535 25.8358L71.9241 28.3705L81.4477 35.2133C82.213 35.7632 83.2915 35.3567 83.5034 34.4385Z'
                fill='white' />
              <path
                d='M83.0565 20.207L69.7788 27.6293L72.2969 34.2949C72.3604 34.4629 72.6086 34.4228 72.616 34.2434L72.8302 29.0313L83.0565 20.207Z'
                fill='#D9D9D9' />
              <defs>
                <linearGradient id='paint0_linear_1306_1279' x1='93.2844' y1='10.6393' x2='49.5753' y2='49.0703'
                  gradientUnits='userSpaceOnUse'>
                  <stop stopColor='#390EF6' />
                  <stop offset='0.387508' stopColor='#9244F4' />
                  <stop offset='0.806162' stopColor='#390EF6' />
                </linearGradient>
              </defs>
            </svg>
          </Box>
          <Heading fontSize='36px' mt='10px' mb='20px' color='#FFFFFF' textAlign='center'>
            Sign In
          </Heading>
          <Text
            w='100%'
            textAlign='center'
            mb='36px'
            ms='4px'
            color='white'
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='1'
          direction='column'
          w={{ 'base': '100%', 'md': '420px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ 'base': 'auto', 'lg': 'unset' }}
          me='auto'
          mb={{ 'base': '20px', 'md': 'auto' }}
          gap='22px'>
          {
            showOtpInput ? (
              <>
                <MemoizedInput
                  onChange={_OnOtpChange}
                  h='45px'
                  placeholder='e.g. "Otp 1234"'
                  label='Otp'
                  value={otp}
                  color='white'
                  bg='rgba(255, 255, 255, 0.33)'
                  onKeyPress={event => 'Enter' === event.key && _AccountLogin(event)}
                  isInvalid={!_.isEmpty(error.otp)}
                  error={error.otp}
                  isRequired={true}
                />
              </>
            ) : (
              <>
                <MemoizedInput
                  w='100%'
                  onChange={_OnEmailChange}
                  ms={{ 'base': '0px', 'md': '0px' }}
                  type='email'
                  label='Email'
                  h='45px'
                  placeholder='mail@simmmple.com'
                  color='white'
                  bg='rgba(255, 255, 255, 0.33)'
                  onKeyPress={event => 'Enter' === event.key && _AccountLogin(event)}
                  isInvalid={!_.isEmpty(error.email)}
                  error={error.email}
                  isRequired={true}
                />
                <Flex position='relative' w='100%' size='md' alignItems='center' justifyContent='space-between'>
                  <MemoizedInput
                    w='100%'
                    label='Password'
                    placeholder='Min. 8 characters'
                    color='white'
                    h='45px'
                    type={show ? 'text' : 'password'}
                    bg='rgba(255, 255, 255, 0.33)'
                    onKeyPress={event => 'Enter' === event.key && _AccountLogin(event)}
                    onChange={_OnPasswordChange}
                    isInvalid={!_.isEmpty(error.password)}
                    error={error.password}
                    isRequired={true}
                    icon={(
                      <Flex position='absolute' right='12px' top='calc(32px/2)' zIndex={1000}>
                        <Icon
                          color='white'
                          _hover={{ 'cursor': 'pointer' }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={() => setShow(!show)}
                        />
                      </Flex>
                    )}
                  />
                </Flex>
              </>
            )
          }
          <SubmitButton
            onClick={_AccountLogin}
            bg='orange.300'
            _hover={{ 'bg': 'orange.400' }}
            _active={{ 'bg': 'orange.400' }}
            _focus={{ 'bg': 'orange.400' }}
            _blur={{ 'bg': 'orange.300' }}
            w='100%'
            h='45px'
            mt='12px'
            defaultText='Sign in'
            color='black'
            iconColor='black'
            disabled={loading}
            isLoading={loading}
            noIcon />
        </Flex>
      </Flex>
    </Box>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'AccountUpdate': PropTypes.func,
  'PlatformUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'AccountUpdate': u => __dispatch({ 'type': 'ACCOUNT_UPDATE', 'Account': u }),
  'PlatformUpdate': p => __dispatch({ 'type': 'PLATFORM_UPDATE', 'Platform': p }),
  'CustomerUpdate': u => __dispatch({ 'type': 'CUSTOMER_UPDATE', 'Customer': u }),
  'VendorUpdate': u => __dispatch({ 'type': 'VENDOR_UPDATE', 'Vendor': u }),
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
