/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RouteReadQuery($accountId: ID!, $type: CUSTOMER_TYPE!, $take: PositiveInt, $skip: Int) {
  RouteRead(accountId: $accountId, type: $type, take: $take, skip: $skip) {
    id,
    displayName,
    type,
    message,
    status
  }
}
`
