/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiPencil, HiTrash } from 'react-icons/hi2' // Npm: React icons.
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * OBJECTS
 */
const Index = ({
  rateId,
  ratePlanId,
  ratePlanIds,
  onRatePlanUpdate,
  onRatePlanDelete,
  PassOnUpdate,
  selectedIds
}) => (
  <Menu placement='left'>
    <MenuButton
      as={Button}
      margin='auto'
      borderRadius='8px'
      color='gray.500'
      bg='transparent'>
      <HiPencil size={18} />
    </MenuButton>
    <MenuList
      className='dropDownMenu'
      maxHeight='400px'
      overflow='auto'>
      <MenuItem
        gap='12px'
        display='flex'
        color='gray.500'
        onClick={() => {
          // Push rate id to url.
          PassOnUpdate({ ratePlanIds, ratePlanId, rateId })

          // Execute handler.
          onRatePlanUpdate(selectedIds)
        }}
        minH='40px'>
        <HiPencil size={18} />
        <span>Edit Rate Plan</span>
      </MenuItem>
      <MenuItem
        gap='12px'
        display='flex'
        color='gray.500'
        onClick={() => {
          // Push rate id to url.
          PassOnUpdate({ ratePlanIds, ratePlanId, rateId })

          // Execute handler.
          onRatePlanDelete(selectedIds)
        }}
        minH='40px'>
        <HiTrash />
        <span>Delete Rate Plan</span>
      </MenuItem>
    </MenuList>
  </Menu>
)


/*
 * PROPTYPES
 */
Index.propTypes = {
  'rateId': PropTypes.string,
  'ratePlanId': PropTypes.string,
  'ratePlanIds': PropTypes.array,
  'onRatePlanUpdate': PropTypes.func,
  'onRatePlanDelete': PropTypes.func,
  'selectedIds': PropTypes.array.isRequired,
  'PassOnUpdate': PropTypes.func
}


/*
 * REDUX
 */
const _MapDispatchToProps = __dispatch => ({
  'PassOnUpdate': u => __dispatch({ 'type': 'PASS_ON_UPDATE', 'PassOn': u })
})


/*
 * EXPORT
 */
export default connect(void 0, _MapDispatchToProps)(Index)
