/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query RoutePlanReadQuery($routePlanId: ID) {
  RoutePlanRead(routePlanId: $routePlanId) {
    id,
    createdAt,
    updatedAt,
    priority,
    isPaused,
    smsType,
    message,
    fakeDlrRate,
    status,
    Mcc {
      mcc,
      countryName,
      Mnc {
        mnc,
        network
      }
    },
    Mnc {
      id,
      mnc,
      network
    },
    Route {
      displayName
    }
  }
}
`
