/*
 * IMPORTS
 */
import { produce } from 'immer' // Npm: Immer library.


/*
 * PACKAGES
 */
export const _initialState = {}


/*
 * OBJECTS
 */
const _CustomerReducer = (__prevState = _initialState, action) =>
  // Return the new state based on the action type
  produce(__prevState, __d => {
    /*
     * Switch case for handling actions on
     * Customer store.
     */
    switch (action.type) {
      case 'CUSTOMER_UPDATE':
        // Update the draft state with the action payload
        return Object.assign(__d, action.Customer)
      case 'CUSTOMER_CLEAR':
        // Reset the draft state to initial state
        return __d = _initialState
      default:
        // Do nothing for other action types
        return __prevState
    }
  })



/*
 * EXPORTS
 */
export default _CustomerReducer
