/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop types.
import _ from 'underscore' // Npm: underscore library.
import { TypeAnimation } from 'react-type-animation' // Npm: React type animation.
import { HiNoSymbol } from 'react-icons/hi2' // Npm: React icons.
import {
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * STYLES
 */
import './index.style.css'


/*
 * OBJECTS
 */
const Index = ({ title, isLoading, isEmpty, chopHeightFromHundredPercentage }) => {
  // Const assignment.
  const _sequence = _.shuffle([
    'Hang tight!',
    3000,
    "Just a moment, we're almost there!",
    1000,
    "Sit tight, we're working behind the scenes.",
    500,
    'Patience, your request is in motion.',
    1000,
    "We're on it, stay tuned!",
    322,
    'A brief pause for greatness.',
    1000,
    "Grab a snack, we'll be quick!",
    1000
  ])

  // Return component.
  return isLoading && !isEmpty ? (
    <Flex className='nothingFound' minHeight={`calc(100% - ${chopHeightFromHundredPercentage}px)`} maxHeight={`calc(100% - ${chopHeightFromHundredPercentage}px)`}>
      <Spinner mb='12px' size='sm' color='rgba(141, 151, 182, 1)' />
      <TypeAnimation
        sequence={_sequence}
        speed={300}
        wrapper='span'
        cursor={true}
        repeat={Infinity}
        style={{ 'fontSize': '1em', 'display': 'inline-block', 'fontWeight': '600', 'color': '#8F9BBA' }}
      />
    </Flex>
  ) : isEmpty ? (
    <Flex className='nothingFound' minHeight={`calc(100% - ${chopHeightFromHundredPercentage}px)`} maxHeight={`calc(100% - ${chopHeightFromHundredPercentage}px)`}>
      <HiNoSymbol style={{ 'marginBottom': '12px' }} size={27} color='rgba(141, 151, 182, 1)' />
      <Text
        fontWeight={500}
        fontSize='Clamp(13px, 1.5vw, 15px)'
        textAlign='center'
        color='rgba(141, 151, 182, 1)'>{title ?? 'Nothing Found'}</Text>
    </Flex>
  ) : <></>
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isLoading': PropTypes.bool,
  'isEmpty': PropTypes.bool,
  'title': PropTypes.string,
  'chopHeightFromHundredPercentage': PropTypes.any
}


/*
 * EXPORT
 */
export default Index
