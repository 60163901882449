/*
 * IMPORT
 */
import React from 'react' // Npm: react.js library.
import Chart from 'react-apexcharts' // Npm: Apex charts.
import _ from 'underscore' // Npm: Underscore utility library.
import {
  Box,
  Flex,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import Card from 'components/Card'


/*
 * GRAPHS
 */
import SystemInformationReadSubscription from './__subscription__/index.systemInformation.read.subscription'
import { useSubscription } from '@apollo/client'


/*
 * OBJECTS
 */
const Index = () => {
  // Const assignment.
  const $options = {
    'chart': {
      'type': 'line',
      'animations': {
        'enabled': true,
        'easing': 'easeinout',
        'speed': 800,
        'animateGradually': {
          'enabled': true,
          'delay': 150
        },
        'dynamicAnimation': {
          'enabled': true,
          'speed': 450
        }
      },
      'sparkline': {
        'enabled': true
      },
      'toolbar': {
        'show': false
      },
      'dropShadow': {
        'enabled': true,
        'top': 13,
        'left': 0,
        'blur': 10,
        'opacity': 0.1,
        'color': '#3B2CF3'
      }
    },
    'xaxis': {
      'labels': {
        'show': false,
        'style': {
          'colors': '#3B2CF3'
        }
      },
      'axisBorder': {
        'show': true
      }
    },
    'yaxis': {
      'min': 0,
      'max': 100,
      'title': {
        'show': false
      },
      'labels': {
        'style': {
          'colors': '#3B2CF3'
        },
        'show': false
      }
    },
    'colors': ['#A493F5'],
    'markers': {
      'size': 0,
      'colors': 'white',
      'strokeColors': '#3B2CF3',
      'strokeWidth': 0,
      'strokeOpacity': 0.9,
      'strokeDashArray': 0,
      'fillOpacity': 0,
      'discrete': [],
      'shape': 'circle',
      'radius': 0,
      'offsetX': 0,
      'offsetY': 0,
      'showNullDataPoints': true
    },
    'tooltip': {
      'theme': 'dark'
    },
    'dataLabels': {
      'enabled': false
    },
    'stroke': {
      'curve': 'smooth',
      'type': 'gradient',
      'width': 2
    },
    'dropShadow': {
      'enabled': true,
      'top': 0,
      'left': 0,
      'blur': 5,
      'opacity': 0.5
    },
    'grid': {
      'show': false,
      'column': {
        'colors': ['transparent'],
        'opacity': 0.5
      }
    }
  }

  // Hook assignment.
  const [, setForceReRender] = React.useState('')
  const _serverLoadRef = React.useRef([0])

  // Event handler.
  useSubscription(SystemInformationReadSubscription, {
    'onData': ({ data, error }) => {
      // If subscription caught an exception then report failure.
      if (error instanceof Error) return error

      // Only update if data is available.
      if (!_.isEmpty(data?.data?.SystemInformationRead)) {
        // Push notification to user.
        _serverLoadRef.current = [..._serverLoadRef.current, parseInt(data?.data?.SystemInformationRead?.currentLoad?.currentLoad ?? 0, 10) ?? 0]
        _serverLoadRef.current = _.unique(_.compact(_serverLoadRef.current))
      }

      // Update state.
      return setForceReRender(String.random(9))
    }
  })

  // Return component.
  return (
    <Card minH='420px' h='100%' align='center' direction='column' bg='linear-gradient(23deg, #BCC0FF 0%, #FFDBE7 45.69%, #FFEED4 99.80%)' p='0' boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' align='start' pt='5px'>
        <Flex flexDirection='column' align='start' me='20px'>
          <Flex w='100%' p='20px' flexDir='column' justifyContent='flex-start'>
            <Text
              color='secondaryGray.900'
              fontWeight='400'
              fontSize='24px'
              textAlign='left'
              lineHeight='28px'>
              System Load
            </Text>
            <Text
              color='secondaryGray.900'
              fontWeight='700'
              fontSize='16px'>
              Live monitoring of server load in %
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Box h='100%' mt='auto' overflow='hidden' borderBottomLeftRadius={22} borderBottomRightRadius={22}>
        <Chart
          options={$options}
          series={[
            {
              'name': 'ServerLoad',
              'data': _.unique(_.compact(_serverLoadRef.current))
            }
          ]}
          type='line'
          width='100%'
          height='100%'
          redrawOnParentResize={true}
        />
      </Box>
    </Card>
  )
}




/*
 * EXPORTS
 */
export default Index
