/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for checking props type.
import Chart from 'react-apexcharts' // Npm: Apex charts.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiChatBubbleBottomCenterText } from 'react-icons/hi2' // Npm: React Icons for icons.
import { Flex, Text, } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import Card from 'components/Card'
import DateRangePicker from 'components/DateRangePicker'


/*
 * GRAPHS
 */
import SmsCountDeliveredQuery from './__query__/index.sms.countDelivered.query'
import SmsCountSentQuery from './__query__/index.sms.countSent.query'
import SmsCountFailedQuery from './__query__/index.sms.countFailed.query'
import SmsCountPendingQuery from './__query__/index.sms.countPending.query'


/*
 * STYLES
 */
import { chartStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ customer }) => {
  // Const assignment.
  const $options = {
    'chart': {
      'height': 300,
      'type': 'polarArea',
    },
    'colors': ['rgba(60,16,246, 0.3)', 'rgba(142,73,238, 0.3)', 'rgba(251, 234, 212, 0.3)', 'rgba(159,60,155, 0.3)'],
    'labels': ['Delivered', 'Failed', 'Accepted', 'Sent'],
    'fill': {
      'opacity': 1
    },
    'stroke': {
      'width': 1,
      'colors': undefined
    },
    'yaxis': {
      'show': false
    },
    'legend': {
      'position': 'bottom'
    },
    'plotOptions': {
      'polarArea': {
        'rings': {
          'strokeWidth': 0
        },
        'spokes': {
          'strokeWidth': 0
        }
      }
    },
    'theme': {
      'monochrome': {
        'enabled': false,
        'shadeTo': 'light',
        'shadeIntensity': 0.6
      }
    },
    'polarArea': {
      'rings': {
        'strokeWidth': 1,
        'strokeColor': '#e8e8e8'
      },
      'spokes': {
        'strokeWidth': 1,
        'connectorColors': '#e8e8e8'
      }
    }
  }

  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const _QuerySmsCountSent = useQuery(SmsCountSentQuery, {
    'variables': {
      'customerId': customer?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _QuerySmsCountFailed = useQuery(SmsCountFailedQuery, {
    'variables': {
      'customerId': customer?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _QuerySmsCountPending = useQuery(SmsCountPendingQuery, {
    'variables': {
      'customerId': customer?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _QuerySmsCountDelivered = useQuery(SmsCountDeliveredQuery, {
    'variables': {
      'customerId': customer?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })

  // Const assignment.
  const _totalDeliveredCount = _QuerySmsCountDelivered?.data?.SmsCountDelivered?.count
  const _totalSentCount = _QuerySmsCountSent?.data?.SmsCountSent?.count
  const _totalFailedCount = _QuerySmsCountFailed?.data?.SmsCountFailed?.count
  const _totalPendingCount = _QuerySmsCountPending?.data?.SmsCountPending?.count

  // Const assignment.
  const _isInputDisabled = _QuerySmsCountDelivered?.loading || _QuerySmsCountSent?.loading || _QuerySmsCountFailed?.loading || _QuerySmsCountPending?.loading

  // Return component.
  return (
    <Card w='100%' h='100%' minH='420px' maxH='420px' pb='22px' align='center' direction='column' bg='linear-gradient(23deg,  #FFDBE7 0%, #BCC0FF 99.80%)' p='0' boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' align='start' pt='5px'>
        <Flex w='100%' flexDirection='row' align='center' justify='space-between' me='20px'>
          <Flex w='100%' p='20px' flexDir='column' justifyContent='flex-start'>
            <Text
              color='secondaryGray.900'
              fontWeight='400'
              fontSize='24px'
              textAlign='left'
              lineHeight='28px'>
              Message State
            </Text>
            <Text
              color='secondaryGray.900'
              fontWeight='700'
              textAlign='left'
              fontSize='16px'>
              Live monitoring of sms passing through %
            </Text>
          </Flex>
          <DateRangePicker startDate={dateRange?.selection?.startDate} endDate={dateRange?.selection?.endDate} setSelectDate={setDateRange} isLoading={_isInputDisabled} />
        </Flex>
      </Flex>
      {
        _totalSentCount > 0 ? (
          <Chart
            style={chartStyle}
            options={$options}
            series={[_totalDeliveredCount, _totalFailedCount, _totalPendingCount, _totalSentCount]}
            type='polarArea'
            height='300px'
            width='100%'
            redrawOnParentResize={true}
          />
        ) : (
          <Flex w='70%' h='100%' align='center' justify='center' flexDir='column' gap='12px' mx='auto'>
            <HiChatBubbleBottomCenterText size={21} />
            <Text w='70%' fontSize='lg'>Seems that you haven't sent any message Today.</Text>
          </Flex>
        )
      }
    </Card>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'customer': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORTS
 */
export default connect(_MapStateToProps)(Index)
