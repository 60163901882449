/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: Prop Types for checking the props type.
import NaturalCompare from 'natural-compare' // Npm: Sorting library.
import _ from 'underscore' // Npm: Underscore.js for utility functions.
import { BsCheckAll } from 'react-icons/bs' // Npm: React Icons for icons.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { HiXCircle } from 'react-icons/hi2' // Npm: React Icons for icons.
import {
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI A simple, modular and accessible component library for React.


/*
 * COMPONENTS
 */
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import DltDirectoryReadQuery from './__query__/index.dltDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ customer, bg, color, dltDirectoryValue, dltValue, inValidDlt, disabled, inValidDltDirectory, isRequired, onChange }) => {
  // Hook assignment.
  const [dltDirectory, setDltDirectory] = React.useState(dltDirectoryValue)
  const [dlt, setDlt] = React.useState([])
  const _QueryDltDirectoryRead = useQuery(DltDirectoryReadQuery, { 'variables': { 'customerId': customer?.id, 'take': 5000, 'skip': 0 }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  React.useEffect(() => {
    /*
     * Update selected country if dltDirectory value
     * Is passed in params.
     */
    dltDirectoryValue && _.isEmpty(dltDirectory) && setDltDirectory(dltDirectoryValue ?? '')
    dltValue && _.isEmpty(dlt) && setDlt(dltValue ?? [])
  }, [dltDirectoryValue, dltValue])

  // Const assignment.
  const _selectedTarget = (dltDirectory)?.split?.('(')?.[1]?.split?.(')')?.[0]
  const _selectedDltDirectory = _QueryDltDirectoryRead?.data?.DltDirectoryRead?.filter?.(j => 0 < j.id?.includes(_selectedTarget))
  const _firstSelectedDltDirectory = _selectedDltDirectory?.[0]

  // Return the JSX.
  return (
    <Flex w='100%' flexDir='column' gap='22px'>
      <MemoizedSelect
        color={color}
        bg={bg}
        label='Entity'
        disabled={_QueryDltDirectoryRead?.loading || disabled}
        name='dltDirectory'
        value={dltDirectory}
        placeholder='Select Folder'
        options={_.compact(_QueryDltDirectoryRead?.data?.DltDirectoryRead?.map?.(j => 'READ_SUCCESSFUL' === j.status ? `${j.displayName} (${j.id})` : void 0))?.sort((a, b) => NaturalCompare(a, b))}
        isInvalid={inValidDltDirectory}
        onChange={__event => {
          // Update states.
          setDltDirectory(__event.target.value)
          setDlt([])

          // Return updated dltDirectory and dlt.
          onChange({
            'dlt': [],
            'dltDirectory': __event.target.value,
            'raw': []
          })
        }}
      />
      <FormControl flexDir='column'>
        <FormLabel
          display='flex'
          alignItems='center'
          style={{ 'pointerEvents': 'none' }}>
          Template Id's{' '}{isRequired ? <Text color='red'>*</Text> : void 0}
        </FormLabel>
        <Menu closeOnSelect={false} position='relative' disabled={_.isEmpty(_firstSelectedDltDirectory) || _QueryDltDirectoryRead?.loading || 0 === _firstSelectedDltDirectory?.Dlt?.length || disabled} w='100%'>
          <MenuButton
            type='button'
            disabled={_.isEmpty(_firstSelectedDltDirectory) || _QueryDltDirectoryRead?.loading || disabled || 0 === _firstSelectedDltDirectory?.Dlt?.length}
            h='40px'
            px='4'
            onClick={e => e.stopPropagation()}
            m='0'
            w='100%'
            color={color}
            opacity={(_.isEmpty(_firstSelectedDltDirectory) || _QueryDltDirectoryRead?.loading || 0 === _firstSelectedDltDirectory?.Dlt?.length || disabled) ? 0.5 : 1}
            transition='all 0.2s'
            textAlign='left'
            borderRadius='12px'
            rightIcon={<BsCheckAll />}
            bg={inValidDlt ? 'rgb(255,255,255,0.8)' : bg ?? 'gray.100'}
            _hover={{ 'bg': bg ?? 'gray.100' }}
            _expanded={{ 'bg': bg ?? 'gray.100' }}
            _focus={{ 'bg': bg ?? 'gray.100' }}
            boxShadow={inValidDlt ? '0 0 0 1.5px #EE5D50' : void 0}>
            {(_.isString(dlt) && !_.isEmpty(dlt) ? [dlt] : dlt)?.length ?? 0} Selected
          </MenuButton>
          <MenuList borderRadius={12} h='250px' overflow='auto' minW='300px'>
            {_QueryDltDirectoryRead?.data?.DltDirectoryRead?.map?.(item => item?.id?.includes(_selectedTarget) ? item?.Dlt?.map?.(r => _.isEmpty(r?.displayName) ? void 0 : (
              <MenuItem closeOnSelect={false} key={String.random(8)}>
                <Checkbox
                  disabled={0 === _selectedDltDirectory.length}
                  name='dlt'
                  onChange={() => {
                    // Update state of dlts.
                    setDlt(j => {
                      /*
                       * If only one selection is allowed
                       * Then clear the array.
                       */
                      if (j.includes(`${r.displayName} (${r?.id})`)) {
                        // Const assignment.
                        const _data = _.without(j, `${r.displayName} (${r?.id})`)

                        // Return updated mcc and mnc.
                        onChange({ 'dlt': _data, 'dltDirectory': dltDirectory, 'raw': _.without(j.raw ?? [], r) })

                        // Update state of dlts.
                        return _data
                      }

                      // Return updated dlt.
                      onChange({ 'dlt': [...j, `${r.displayName} (${r?.id})`], 'dltDirectory': dltDirectory, 'raw': [...(j.raw ?? []), r], 'rawDlt': _firstSelectedDltDirectory })

                      // Update state of dlts.
                      return [...j, `${r.displayName} (${r?.id})`]
                    })
                  }}
                  isChecked={dlt.includes(`${r.displayName} (${r?.id})`)}>
                  {`${r.displayName} (${r?.id})`}
                </Checkbox>
              </MenuItem>
            )) : void 0)}
          </MenuList>
          <Flex position='absolute' right={0} bottom={0} zIndex={10}>
            <IconButton
              aria-label='Clear All'
              disabled={_.isEmpty(_firstSelectedDltDirectory) || _QueryDltDirectoryRead?.loading || disabled || 0 === _firstSelectedDltDirectory?.Dlt?.length}
              _hover={{ 'bg': 'none' }}
              _active={{ 'bg': 'none' }}
              onClick={() => {
                // Update dlt.
                setDlt([])

                // Return updated dltIds.
                onChange({ 'dlt': [], 'dltDirectory': '', 'raw': [], 'rawDlt': {} })
              }}
              bg='none'
              p='0'>
              {0 === dlt?.length ? (<HiXCircle fontSize='20px' color='#c4c4c4' />) : (<HiXCircle fontSize='20px' color='#FF416C' />)}
            </IconButton>
          </Flex>
        </Menu>
      </FormControl>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'dltDirectoryValue': PropTypes.string,
  'dltValue': PropTypes.array,
  'inValidDlt': PropTypes.bool,
  'disabled': PropTypes.bool,
  'inValidDltDirectory': PropTypes.bool,
  'onChange': PropTypes.func,
  'isRequired': PropTypes.bool,
  'bg': PropTypes.string,
  'color': PropTypes.string
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
