/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import PaymentReadQuery from './__query__/index.payment.read.query'
import PaymentUpdateMutation from './__mutation__/index.payment.update.mutation'
import PaymentCreateMutation from './__mutation__/index.payment.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ isOpen, isCreateOnly, isVendorOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryPaymentRead, QueryPaymentReadResponse] = useLazyQuery(PaymentReadQuery, { 'variables': { 'paymentId': passOn?.paymentId } })
  const [MutationPaymentCreate, MutationPaymentCreateResponse] = useMutation(PaymentCreateMutation)
  const [MutationPaymentUpdate, MutationPaymentUpdateResponse] = useMutation(PaymentUpdateMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'amount': JoiBrowser.number().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute account registration mutation.
    const _MutationPayment = await (isCreateOnly ? MutationPaymentCreate : MutationPaymentUpdate)({
      'variables': {
        'input': [
          {
            'amount': _formDataRef?.current?.amount,
            [isCreateOnly ? isVendorOnly ? 'vendorId' : 'customerId' : 'id']: isCreateOnly ? isVendorOnly ? passOn?.vendorId : passOn?.customerId : passOn?.paymentId
          }
        ]
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationPayment instanceof Error) return _MutationPayment

    // On Successful response from the mutation.
    if ('CREATE_SUCCESSFUL' === _.first(_MutationPayment?.data?.PaymentCreate)?.status) onClose?.()
    if ('UPDATE_SUCCESSFUL' === _.first(_MutationPayment?.data?.PaymentUpdate)?.status) onClose?.()

    // Style Guide.
    return toast(_.first(_MutationPayment?.data?.[isCreateOnly ? 'PaymentCreate' : 'PaymentUpdate'])?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryPaymentReadQuery = await QueryPaymentRead({ 'variables': { 'paymentId': isCreateOnly ? 'UN_KNOWN' : passOn?.paymentId } })

      // If query caught an exception then report failure.
      if (_QueryPaymentReadQuery instanceof Error) return _QueryPaymentReadQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (!_.isEmpty(_QueryPaymentReadQuery?.data?.PaymentRead)) {
        // Update form data.
        _formDataRef.current = {
          'amount': _.first(_QueryPaymentReadQuery?.data?.PaymentRead)?.amount
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationPaymentCreateResponse.loading || MutationPaymentUpdateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryPaymentReadResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        disabled={_isInputDisabled}
        isRequired={true}
        name='amount'
        label={`Amount (in ${passOn?.currency ?? 'EUR'})`}
        placeholder={`e.g. "200 ${passOn?.currency ?? 'EUR'}"`}
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
          }
        }}
        error={error}
        isInvalid={error?.includes('amount')}
        data={_formDataRef?.current?.amount}
      />
      <SubmitButton
        isLoading={_isLoading}
        defaultText='Upsert Payment'
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isVendorOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
