/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io'
import { connect } from 'react-redux' // Npm: React Redux for state management.
import PropTypes from 'prop-types' // Npm: react.js library.
import {
  Button,
  Flex,
  Input,
  Table,
  useBreakpointValue,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import TableSpinner from 'components/TableSpinner'
import Modal from 'components/Modal'
import ContactBookDirectoryUpsert from 'components/ContactBookDirectoryUpsert'
import ContactBookDirectoryDropDownOptions from 'components/ContactBookDirectoryDropDownOptions'
import ContactBookDirectoryDelete from 'components/ContactBookDirectoryDelete'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import ContactBookDirectoryReadQuery from './__query__/index.contactBookDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ customer }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [contactBookDirectoryToSearch, setContactBookDirectoryToSearch] = React.useState('')
  const { 'isOpen': isContactBookDirectoryCreateOpen, 'onOpen': onContactBookDirectoryCreateOpen, 'onClose': onContactBookDirectoryCreateClose } = useDisclosure()
  const { 'isOpen': isContactBookDirectoryUpdateOpen, 'onOpen': onContactBookDirectoryUpdateOpen, 'onClose': onContactBookDirectoryUpdateClose } = useDisclosure()
  const { 'isOpen': isContactBookDirectoryDeleteOpen, 'onOpen': onContactBookDirectoryDeleteOpen, 'onClose': onContactBookDirectoryDeleteClose } = useDisclosure()
  const _QueryContactBookDirectoryRead = useQuery(ContactBookDirectoryReadQuery, { 'variables': { 'customerId': customer?.id, contactBookDirectoryToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onContactBookDirectoryToSearchInputChange = React.useCallback(Debounce(e => setContactBookDirectoryToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data loading check.
  if (!_QueryContactBookDirectoryRead.loading && 0 < _QueryContactBookDirectoryRead.data?.ContactBookDirectory?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryContactBookDirectoryRead.data?.ContactBookDirectoryRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='contactBookDirectory base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Flex gap='12px'>
              <Tooltip label='Create New' fontSize='sm'>
                <Button
                  leftIcon={<IoMdAdd />}
                  onClick={onContactBookDirectoryCreateOpen}
                  style={buttonStyle}>
                  Create New
                </Button>
              </Tooltip>
            </Flex>
            <DownloadToExcel
              cellsData={0 === _QueryContactBookDirectoryRead?.data?.ContactBookDirectoryRead?.length || !_.every(_.pluck(_QueryContactBookDirectoryRead?.data?.ContactBookDirectoryRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryContactBookDirectoryRead?.data?.ContactBookDirectoryRead.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Display Name': item.displayName ?? '-',
                'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD')
              }))}
              headersData={[
                'S.No.',
                'Display Name',
                'CreatedAt',
                'UpdatedAt'
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Display Name</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}>Actions</Td>
                </Tr>
                <Tr >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Input
                      placeholder='Search'
                      className='filter searchInput'
                      bg='gray.100'
                      defaultValue={contactBookDirectoryToSearch}
                      onChange={_onContactBookDirectoryToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td></Td>
                </Tr>
              </Thead>
              {_QueryContactBookDirectoryRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryContactBookDirectoryRead.data?.ContactBookDirectoryRead?.length || !_.every(_.pluck(_QueryContactBookDirectoryRead.data?.ContactBookDirectoryRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryContactBookDirectoryRead.data?.ContactBookDirectoryRead?.map(({ id, createdAt, updatedAt, displayName, ContactBook }, __index) => (
                    <Tr key={String.random(9)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <ContactBookDirectoryDropDownOptions
                            contactBookDirectoryId={id}
                            onContactBookDirectoryUpdate={onContactBookDirectoryUpdateOpen}
                            onContactBookDirectoryDelete={onContactBookDirectoryDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='ContactBook Directory Create'
          isOpen={isContactBookDirectoryCreateOpen}
          onClose={onContactBookDirectoryCreateClose}>
          <ContactBookDirectoryUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='ContactBook Directory Update'
          isOpen={isContactBookDirectoryUpdateOpen}
          onClose={onContactBookDirectoryUpdateClose}>
          <ContactBookDirectoryUpsert />
        </Modal>
        <Modal
          size='md'
          title='ContactBook Directory Delete'
          isOpen={isContactBookDirectoryDeleteOpen}
          onClose={onContactBookDirectoryDeleteClose}>
          <ContactBookDirectoryDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryContactBookDirectoryRead?.data?.ContactBookDirectoryRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * EXPORT
 */
Index.propTypes = {
  'customer': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
