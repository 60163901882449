/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation ContactBookUpsertMutation($contactBookDirectoryId: ID, $contactBookId: ID, $contactBook: String) {
  ContactBookUpsert(contactBookDirectoryId: $contactBookDirectoryId, contactBookId: $contactBookId, contactBook: $contactBook) {
    id
    message
    status
  }
}
`
