/*
 * IMPORTS
 */
import { produce } from 'immer' // Npm: Immer library.


/*
 * PACKAGES
 */
export const _initialState = {}


/*
 * OBJECTS
 */
const _RouteReducer = (__prevState = _initialState, action) => produce(__prevState, __d => {
  /*
   * Switch case for handling actions on
   * Route store.
   */
  switch (action.type) {
    case 'ROUTE_UPDATE':
      // Update the draft state with the action payload
      return Object.assign(__d, action.Route)
    case 'ROUTE_CLEAR':
      // Reset the draft state to initial state
      return __d = _initialState
    default:
      // Do nothing for other action types
      return __prevState
  }
})



/*
 * EXPORTS
 */
export default _RouteReducer
