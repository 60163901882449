/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Terminal from 'react-console-emulator' // Npm: React console emulator.
import ReactJsonView from 'react-json-view' // Npm: React json pretty.
import AsciiTable from 'ascii-table' // Npm: Ascii table.
import $ from 'jquery' // Npm: Jquery.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useQuery, useSubscription } from '@apollo/client' // Npm: Apollo client.
import {
  Flex,
  Spinner,
  Text
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * GRAPHS
 */
import RouteReadQuery from './__query__/index.route.read.query'
import SystemInformationReadUniqueQuery from './__query__/index.systemInformation.readUnique.query'
import SmppLogsSubscription from './__subscription__/index.smpp.logs.subscription'


/*
 * STYLES
 */
import './index.css'


/*
 * OBJECTS
 */
const Index = ({ passOn, onClose }) => {
  // Hook assignment.
  const [smppToWatch, setSmppToWatch] = React.useState([])
  const [forceReRender, setForceReRender] = React.useState(0)
  const _terminalRef = React.useRef({})
  const _QueryRouteRead = useQuery(RouteReadQuery, { 'variables': { 'routeId': passOn?.routeId }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _QuerySystemInformationReadUnique = useQuery(SystemInformationReadUniqueQuery, { 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })

  // Event handler.
  useSubscription(SmppLogsSubscription, {
    'variables': {
      'ip': smppToWatch?.[0],
      'port': smppToWatch?.[1]
    },
    'onData': ({ data, error }) => {
      // If subscription caught an exception then report failure.
      if (error instanceof Error) return error

      // If data is available then push to terminal.
      if (!_.isEmpty(data?.data?.SmppLogs)) _terminalRef.current.pushToStdout(<ReactJsonView src={data?.data?.SmppLogs?.logs ?? {}} enableClipboard={copy => navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))} />)

      // Return void.
      return void 0
    }
  })
  React.useEffect(() => {
    // Import lettercrap.
    import('lib/LetterCrap').then(({ 'default': LetterCrap }) => {
      // Const assignment.
      const _el = $('.letterCrap')

      // If element exists then return.
      0 < _el?.length && _el?.map(j => LetterCrap(_el[j]))
    })
  })
  React.useEffect(() => {
    // Listen to ctrl along with c key press.
    $(document).ready(() => {
      // Local variable.
      let _ctrlDown

      // Variable assignment.
      _ctrlDown = false

      // Const assignment.
      const _cKey = 67
      const _ctrlKey = 17
      const _cmdKey = 91

      // Detect ctrl key.
      $(document).keydown(e => { if (e.keyCode === _ctrlKey || e.keyCode === _cmdKey) _ctrlDown = true }).keyup(e => { if (e.keyCode === _ctrlKey || e.keyCode === _cmdKey) _ctrlDown = false })
      $(document).keydown(e => { if (_ctrlDown && (e.keyCode === _cKey)) setSmppToWatch([]) })
    })
  }, [_terminalRef?.current?.pushToStdout])

  // Object assignment.
  const _commands = {
    'help': {
      'description': 'Shows all commands terminal supports.',
      'usage': 'help',
      'fn': () => {
        // Force ReRender ui.
        setForceReRender(Math.random())

        // Return component.
        return (
          <div key={forceReRender}>
            <Flex className='systemInformationWrapper' flexDir='row'>
              <Flex flex={1}>
                <div className='letterCrap' data-letter-crap={`${window.location.host?.includes('127.0.0.1') || window.location.host?.includes('localhost') ? 'http' : 'https'}://${window.location.host}/images/ubuntu.png`} style={{ 'width': 350, 'height': 350 }}></div>
              </Flex>
              <Flex flex={1} className='systemInformation' flexDir='column'>
                <Flex flexDir='column'>
                  <Text className='systemInformation__title'>System Information</Text>
                  <Text className='systemInformation__content'>Following is the system information of shell running on.This information could be useful in cases where you need system up time and all.</Text>
                </Flex>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Os</Text>: {_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.operatingSystem}</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Host</Text>: {_.last(_QueryRouteRead?.data?.RouteRead)?.displayName}@Smpp.local</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Kernel</Text>: {_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.release}</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Shell</Text>: {_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.user?.shell ?? '/bin/zsh'}</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Uptime</Text>: {_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.uptime}</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Arch</Text>: {_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.cpuArchitecture}</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Free Memory</Text>: {Object.React.FormatBytes(_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.freeMemory)}</Text>
                <Text className='systemInformation__content'><Text as='span' color='gray.500' fontWeight='800'>Total Memory</Text>: {Object.React.FormatBytes(_QuerySystemInformationReadUnique?.data?.SystemInformationReadUnique?.totalMemory)}</Text>
              </Flex>
            </Flex>
            <div>
              <Text mt='0' fontSize='13px' color='gray.500' fontWeight='800'>Basic Commands</Text>
              <Text fontSize='13px'>1. whoami - <span>Display shell user name.</span></Text>
              <Text fontSize='13px'>2. help - <span>Shows command options and there working.</span></Text>
              <Text fontSize='13px'>3. clear - <span>Clear shell standard output.</span></Text>
              <Text fontSize='13px'>4. exit - <span>Exit from shell.</span></Text>
              <Text mt='12px' fontSize='13px' color='gray.500' fontWeight='800'>Route Commands</Text>
              <Text fontSize='13px'>1. count - <span>Return count of route plans this route have.</span></Text>
              <Text fontSize='13px'>2. mcc - <span>Mobile Country Codes.</span></Text>
              <Text fontSize='13px'>3. mcc --count - <span>Number of Mobile Country Codes.</span></Text>
              <Text fontSize='13px'>4. mnc - <span>Mobile Network Codes.</span></Text>
              <Text fontSize='13px'>5. mnc --networks - <span>Mobile Network Operators.</span></Text>
              <Text fontSize='13px'>6. mnc --count - <span>Number of Mobile Network Codes.</span></Text>
              <Text mt='12px' fontSize='13px' color='gray.500' fontWeight='800'>Sms Commands</Text>
              <Text fontSize='13px'>1. sms --count - <span>Total number of SMS.</span></Text>
              <Text fontSize='13px'>2. sms --size (Number) - <span>Shows number of sms on screen.</span></Text>
              <Text fontSize='13px'>3. sms --(accepted | rejected | delivered) --count - <span>Number of (flag) SMS.</span></Text>
              <Text fontSize='13px'>4. sms --(isText | isUnicode) --count - <span>Number of Text or Unicode SMS.</span></Text>
              <Text fontSize='13px'>5. sms --deliveredThrough (smpp | api) - <span>Number of SMS delivered through SMPP or Api.</span></Text>
              <Text mt='12px' fontSize='13px' color='gray.500' fontWeight='800'>Smpp Commands</Text>
              <Text fontSize='13px'>1. smpp --count - <span>Total number of smpp.</span></Text>
              <Text fontSize='13px'>2. smpp --size (Number) - <span>Shows number of smpp on screen.</span></Text>
              <Text fontSize='13px'>3. smpp --ips - <span>Return all ips of smpp separated by ,</span></Text>
              <Text fontSize='13px'>4. smpp --uris - <span>Return all uris of smpp separated by ,</span></Text>
              <Text fontSize='13px'>5. smpp --watch (ip) (port) - <span>Output live logs to the terminal.</span></Text>
            </div>
          </div>
        )
      }
    },
    'clear': {
      'description': 'Clear terminal standard out logs in terminal.',
      'usage': 'clear',
      'fn': () => { _terminalRef?.current?.clearStdout?.(); _terminalRef?.current?.focusTerminal?.() }
    },
    'clear()': {
      'description': 'Clear terminal standard out logs in terminal.',
      'usage': 'clear()',
      'fn': () => { _terminalRef?.current?.clearStdout?.(); _terminalRef?.current?.focusTerminal?.() }
    },
    'exit': {
      'description': 'Exit terminal by clearing terminal standard out logs in terminal.',
      'usage': 'exit',
      'fn': () => { _terminalRef?.current?.clearStdout?.(); _terminalRef?.current?.focusTerminal?.(); onClose?.() }
    },
    'exit()': {
      'description': 'Exit terminal by clearing terminal standard out logs in terminal.',
      'usage': 'exit()',
      'fn': () => { _terminalRef?.current?.clearStdout?.(); _terminalRef?.current?.focusTerminal?.(); onClose?.() }
    },
    'whoami': {
      'description': 'Display shell user name.',
      'usage': 'whoami',
      'fn': () => _.last(_QueryRouteRead?.data?.RouteRead)?.displayName
    },
    'count': {
      'description': 'Return count of routePlan size.',
      'usage': 'count',
      'fn': () => _.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan'))?.length
    },
    'mcc': {
      'description': 'Mobile Country Codes.',
      'usage': 'mcc',
      'fn': e => {
        // Const assignment.
        const _routeRead = _.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'Mcc')

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === e) return _routeRead?.length?.toString()

        // Return mcc.
        return _routeRead?.map(j => j.mcc)?.join(', ')
      }
    },
    'mnc': {
      'description': 'Mobile Network Codes.',
      'usage': 'mnc',
      'fn': e => {
        // Const assignment.
        const _routeRead = _.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'Mnc')

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === e) return _routeRead?.length?.toString()
        if ('--networks' === e) return _routeRead?.map(j => j.network)?.join(', ')

        // Return mcc.
        return _routeRead?.map(j => j.mnc)?.join(', ')
      }
    },
    'sms': {
      'description': 'Short Messages',
      'usage': 'sms',
      'fn': (j, e) => {
        // Const assignment.
        const _smsRead = _.compact(_.flatten(_.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'Sms')))

        /*
         * If count flag is passed then return count of given command.
         */
        if (('--count' === j && '--delivered' === e) || ('--count' === e && '--delivered' === j)) return _smsRead.filter(r => 'DELIVERED' === r.status).length.toString()
        if (('--count' === j && '--accepted' === e) || ('--count' === e && '--accepted' === j)) return _smsRead.filter(r => 'ACCEPTED' === r.status).length.toString()
        if (('--count' === j && '--rejected' === e) || ('--count' === e && '--rejected' === j)) return _smsRead.filter(r => 'REJECTED' === r.status).length.toString()
        if (('--count' === j && '--isUnicode' === e) || ('--count' === e && '--isUnicode' === j)) return _smsRead.filter(r => r.isUnicodeMessage).length.toString()
        if (('--count' === j && '--isText' === e) || ('--count' === e && '--isText' === j)) return _smsRead.filter(r => !r.isUnicodeMessage).length.toString()
        if ('--count' === j && _.isEmpty(e)) return _smsRead.length.toString()
        if (('--deliveredThrough' === j && !_.isEmpty(e)) || ('--deliveredThrough' === e && !_.isEmpty(j))) return _smsRead.filter(r => !r.isUnicodeMessage && (j?.toUpperCase?.() === r.deliveredThrough?.toUpperCase?.() || e?.toUpperCase?.() === r.deliveredThrough?.toUpperCase?.())).length.toString()

        // Paginated dataset to return.
        const pageSize = '--size' === j && !isNaN(e) && 0 <= Number(e) ? Number(e) : 2
        const _data = _.compact('--latest' === j ? _.last(_smsRead) : '--first' === j ? _.first(_smsRead) : _smsRead.slice(0, pageSize))

        // Const assignment.
        const _AsciiTable = new AsciiTable('Short Messages')

        // Add heading to the table.
        _AsciiTable.setHeading('s.no.', 'id', 'message', 'isUnicode', 'Status')
        _AsciiTable.setTitleAlign(AsciiTable.CENTER)

        // Add data to the table with specified column widths
        _data.forEach((c, __index) => _AsciiTable.addRow(__index + 1, c.id, c.message, c.isUnicodeMessage, c.status))

        // Return ASCII table.
        return _AsciiTable?.toString()
      }
    },
    'smpp': {
      'description': 'Smpp Accounts.',
      'usage': 'smpp',
      'fn': (j, e, r) => {
        // Const assignment.
        const _vendorAccountSmpp = _.compact(_.flatten(_.pluck(_.compact(_.flatten(_.pluck(_.flatten(_.pluck(_QueryRouteRead?.data?.RouteRead, 'RoutePlan')), 'VendorAccount'))), 'Smpp')))

        /*
         * If count flag is passed then return.
         * count of given command.
         */
        if ('--count' === j && _.isEmpty(e)) return _vendorAccountSmpp?.length?.toString()
        if (('--count' === j && '--smpp' === e) || ('--smpp' === e && '--count' === j)) return _vendorAccountSmpp?.length?.toString()
        if (('--ips' === j) || ('--ips' === e)) return _.flatten(_.pluck(_vendorAccountSmpp, 'ip'))?.toString()
        if (('--uris' === j) || ('--uris' === e)) return _vendorAccountSmpp?.map(y => `${y.isSecured ? 'ssmpp' : 'smpp'}://${y.ip}:${y.txPort}`)?.join(', ')
        if (('--watch' === j && !_.isEmpty(e) && _.isString(e))) {
          // If smpp id is not passed then return.
          if (_.isEmpty(e)) return <Text color='red'>Please provide smpp id.</Text>

          // Update smpp id.
          setSmppToWatch([e, r])
        } else {
          // Paginated dataset to return.
          _vendorAccountSmpp.length = ('--size' === j && !isNaN(e) && 0 <= Number(e) ? e : 2)

          // Const assignment.
          const _AsciiTable = new AsciiTable('Vendor Smpp Accounts')
          const _data = _.compact('--latest' === j ? _.last(_vendorAccountSmpp) : '--first' === j ? _.first(_vendorAccountSmpp) : _vendorAccountSmpp)

          // Add heading to the table.
          _AsciiTable.setHeading('.s.no', 'id', 'ip', 'username', 'txPort', 'rxPort')

          // Add data to the table.
          _data.map((u, __index) => _AsciiTable.addRow(__index + 1, u.id, u.ip, u.username, u.txPort, u.rxPort))

          // Return ascii table.
          return _AsciiTable?.toString()
        }

        // Return void.
        return void 0
      },
      'explicitExec': true
    }
  }

  // Return component.
  return (
    <Flex className='RouteLogs' w='100%' h='100%' borderTop='1px solid' borderColor='gray.100'>
      {
        _QueryRouteRead.loading ? (
          <Flex alignItems='center' gap='12px' py='22px' color='gray.500'>
            <Spinner size='sm' />
            <Text>Connecting Terminal Please wait..</Text>
          </Flex>
        ) : (
          <Flex flexDir='column'>
            <Terminal
              ref={_terminalRef}
              commands={_commands}
              readOnly={!_.isEmpty(smppToWatch)}
              autoFocus={true}
              promptLabel={`${_.last(_QueryRouteRead?.data?.RouteRead)?.displayName}@smpp:~$`}
              welcomeMessage={`Welcome to SMPP Terminal! Embark on a journey into live data Please type 'help' for more.`}
              noDefaults />
          </Flex>
        )
      }
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
