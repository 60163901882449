/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation RateCreateMutation($type: CUSTOMER_TYPE!, $currency: Currency!, $displayName: String!) {
  RateCreate(type: $type, currency: $currency, displayName: $displayName) {
    message
    status
  }
}
`
