/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation DltUpsertMutation($dltDirectoryId: ID, $dltId: ID, $displayName: String!, $content: String, $templateId: String, $senderId: String) {
  DltUpsert(dltDirectoryId: $dltDirectoryId, dltId: $dltId, displayName: $displayName, content: $content, templateId: $templateId, senderId: $senderId) {
    id
    message
    status
  }
}
`
