/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
  query RateReadQuery($accountId: ID!, $search: String, $take: PositiveInt, $skip: Int) {
    RateRead(accountId: $accountId, search: $search, take: $take, skip: $skip) {
      id,
      createdAt,
      updatedAt,
      displayName,
      type,
      message,
      status,
      _totalCount,
      Account {
        email
        displayName
      }
    }
  }
`
