/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import DltDirectoryReadUniqueQuery from './__query__/index.dltDirectory.read.query'
import DltDirectoryUpsertMutation from './__mutation__/index.dltDirectory.upsert.mutation'


/*
 * OBJECTS
 */
const Index = ({ customer, isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryDltDirectoryReadUnique, QueryDltDirectoryReadUniqueResponse] = useLazyQuery(DltDirectoryReadUniqueQuery, { 'variables': { 'dltDirectoryId': passOn?.dltDirectoryId } })
  const [MutationDltDirectoryUpsert, MutationDltDirectoryUpsertResponse] = useMutation(DltDirectoryUpsertMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'peId': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    // Execute customer registration mutation.
    const _MutationDltDirectoryUpsert = await MutationDltDirectoryUpsert({
      'variables': {
        'customerId': customer?.id,
        'displayName': _formDataRef?.current?.displayName,
        'dltDirectoryId': isCreateOnly ? void 0 : passOn?.dltDirectoryId,
        'peId': _formDataRef?.current?.peId
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationDltDirectoryUpsert instanceof Error) return _MutationDltDirectoryUpsert

    // On Successful response from the mutation.
    if ('UPSERT_SUCCESSFUL' === _MutationDltDirectoryUpsert?.data?.DltDirectoryUpsert?.status) onClose?.()

    // Style Guide.
    return toast(_MutationDltDirectoryUpsert?.data?.DltDirectoryUpsert?.message)
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryDltDirectoryReadUniqueQuery = await QueryDltDirectoryReadUnique({ 'variables': { 'dltDirectoryId': isCreateOnly ? 'UN_KNOWN' : passOn?.dltDirectoryId } })

      // If query caught an exception then report failure.
      if (_QueryDltDirectoryReadUniqueQuery instanceof Error) return _QueryDltDirectoryReadUniqueQuery

      /*
       * If customer details fetch complete then
       * update its value.
       */
      if (_QueryDltDirectoryReadUniqueQuery?.data?.DltDirectoryReadUnique) {
        // Update form data.
        _formDataRef.current = {
          'dltDirectoryId': passOn?.dltDirectoryId,
          'displayName': _QueryDltDirectoryReadUniqueQuery?.data?.DltDirectoryReadUnique?.displayName,
          'peId': _QueryDltDirectoryReadUniqueQuery?.data?.DltDirectoryReadUnique?.peId
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationDltDirectoryUpsertResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryDltDirectoryReadUniqueResponse?.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} key={forceReRender}>
      <MemoizedInput
        mb='22px'
        disabled={_isInputDisabled}
        isRequired={true}
        name='displayName'
        label='Entity Name'
        placeholder='e.g. "Test Numbers"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('displayName')}
        data={_formDataRef?.current?.displayName}
      />
      <MemoizedInput
        disabled={_isInputDisabled}
        isRequired={true}
        name='peId'
        label='PE ID'
        placeholder='e.g. "1234 5678 9012 3456"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('peId')}
        data={_formDataRef?.current?.peId}
      />
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Folder' : 'Update Folder'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer, 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
