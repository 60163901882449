/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import { MemoizedInput, MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RateReadQuery from './__query__/index.rate.read.query'
import RateUpdateMutation from './__mutation__/index.rate.update.mutation'
import RateCreateMutation from './__mutation__/index.rate.create.mutation'


/*
 * OBJECTS
 */
const Index = ({ type, isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryRateRead, QueryRateReadResponse] = useLazyQuery(RateReadQuery, { 'variables': { 'rateId': passOn?.rateId } })
  const [MutationRateCreate, MutationRateCreateResponse] = useMutation(RateCreateMutation)
  const [MutationRateUpdate, MutationRateUpdateResponse] = useMutation(RateUpdateMutation)
  const _formDataRef = React.useRef({ type })

  // Object assignment.
  const _SubmitForm = async e => {
    // Prevent default behavior.
    e.preventDefault()

    // Reset error.
    setError('')

    // Update _formDataRef with type
    _formDataRef.current = { ..._formDataRef.current, 'type': 'RETAIL' }

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'displayName': JoiBrowser.string().required(),
      'currency': JoiBrowser.string().required()
    }).options({ 'allowUnknown': true })

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = _.pick(_formDataRef.current, _.identity)

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Push rateId if not found on the _formDataRef.
     * also make sure that isCreateOnly is false.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.rateId)) _formDataRef.current = { ..._formDataRef.current, 'rateId': passOn?.rateId }

    // Execute update mutation.
    const _MutationRateUpdate = await [isCreateOnly ? MutationRateCreate : MutationRateUpdate]?.[0]({ 'variables': _.omit(_formDataRef?.current, isCreateOnly ? 'rateId' : void 0) })

    // If mutation caught an exception then report failure.
    if (_MutationRateUpdate instanceof Error) return _MutationRateUpdate

    // Style Guide.
    toast(_MutationRateUpdate?.data?.RateUpdate?.message ?? _MutationRateUpdate?.data?.RateCreate?.message)

    // Execute onClose only if response is successful.
    if ('UPDATE_SUCCESSFUL' === _MutationRateUpdate?.data?.RateUpdate?.status || 'CREATE_SUCCESSFUL' === _MutationRateUpdate?.data?.RateCreate?.status) return onClose?.()

    // Report void 0.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryRateReadQuery = await QueryRateRead({ 'variables': { 'rateId': isCreateOnly ? 'UN_KNOWN' : passOn?.rateId } })

      // If query caught an exception then report failure.
      if (_QueryRateReadQuery instanceof Error) return _QueryRateReadQuery

      /*
       * If rate details fetch complete then
       * update its value.
       */
      if (0 < _QueryRateReadQuery?.data?.RateRead?.length) {
        // Const assignment.
        const _data = _.first(_QueryRateReadQuery?.data?.RateRead)

        // Update form data.
        _formDataRef.current = {
          'rateId': _data.id,
          'displayName': _data.displayName,
          'currency': _data.currency
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationRateUpdateResponse.loading || MutationRateCreateResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryRateReadResponse.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm}>
      <MemoizedInput
        disabled={_isInputDisabled}
        name='displayName'
        label='Name'
        mb='22px'
        placeholder='e.g. "US Rate"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        isInvalid={error?.includes('displayName')}
        data={_formDataRef?.current?.displayName}
      />
      <MemoizedSelect
        disabled={_isInputDisabled}
        name='currency'
        label='Currency'
        placeholder='e.g. "USD"'
        onChange={({ target }) => {
          // Over spreading.
          const { name, value } = target

          // Update form data.
          _formDataRef.current = {
            ..._formDataRef?.current,
            [name]: value
          }
        }}
        error={error}
        options={Object.React.App.enums.CURRENCY_LIST.enums?.map(i => i.key)}
        isInvalid={error?.includes('currency')}
        data={_formDataRef?.current?.currency}
      />
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Rate' : 'Update Rate'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'type': PropTypes.string,
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
