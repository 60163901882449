/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * GRAPHS
 */
const Index = gql`
  query SmsCountDlrQuery($customerId: ID!, $endDate: DateTime!, $startDate: DateTime!) {
    SmsCountDlr(customerId: $customerId, endDate: $endDate, startDate: $startDate) {
      count,
      _totalCount,
      status,
      message
    }
  }
`


/*
 * EXPORTS
 */
export default Index
