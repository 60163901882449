/*
 * IMPORT
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types for React.
import { Scrollbars } from 'react-custom-scrollbars-2' // Npm: React custom scrollbars.
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
  useColorModeValue
} from '@chakra-ui/react' // Npm: Chakra UI components.

/*
 * PACKAGES
 */
import DrawerContext from './index.context'
import { renderThumb, renderTrack, renderView } from './ScrollBar'


/*
 * SIBLINGS
 */
import Content from './Content'


/*
 * OBJECTS
 */
const Index = ({ routes }) => {
  // Const assignment.
  const _variantChange = '0.2s linear'
  const _sidebarRadius = '30px'
  const _sidebarMargins = '0px'

  // Hook assignment.
  const _sidebarBgRef = useColorModeValue('white', 'navy.800')

  // Return component.
  return (
    <Box
      w='20%'
      bg='#F5F8FE'
      display={{ 'sm': 'none', 'xl': 'block' }}
      position='fixed'
      minH='100%'
      fontSize={['clamp(13px, 1.5vw, 15px)']}>
      <Box
        bg={_sidebarBgRef}
        transition={_variantChange}
        ms={{ 'sm': '16px' }}
        my={{ 'sm': '16px' }}
        h='calc(100vh - 32px)'
        m={_sidebarMargins}
        borderRadius={_sidebarRadius}
        minH='100%'
        overflowX='hidden'>
        <Scrollbars
          autoHide
          overflowY='hidden'
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}>
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  )
}
const SidebarResponsive = ({ routes }) => {
  // Hook assignment.
  const [isOpen, setIsDrawerOpen] = React.useContext(DrawerContext)
  const _sidebarBackgroundColorRef = useColorModeValue('white', 'navy.800')
  const _buttonRef = React.useRef()

  // Return component.
  return (
    <Flex display={{ 'sm': 'flex', 'xl': 'none' }} alignItems='center' ml={22}>
      <Drawer
        isOpen={isOpen}
        onClose={setIsDrawerOpen}
        placement={'rtl' === document.documentElement.dir ? 'left' : 'right'}
        finalFocusRef={_buttonRef}>
        <DrawerOverlay />
        <DrawerContent
          w='285px'
          maxW='285px'
          ms={{ 'sm': '16px' }}
          my={{ 'sm': '16px' }}
          mr='16px'
          borderRadius='16px'
          bg={_sidebarBackgroundColorRef}>
          <DrawerCloseButton
            zIndex='3'
            onClose={setIsDrawerOpen}
            _focus={{ 'boxShadow': 'none' }}
            _hover={{ 'boxShadow': 'none' }}
          />
          <DrawerBody maxW='285px' px='0rem' pb='0'>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}>
              <Content routes={routes} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}
const Sidebar = ({ routes }) => {
  // Hook assignment.
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Return component.
  return _isCurrentViewMobile ? <SidebarResponsive routes={routes} /> : <Index routes={routes} />
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'logoText': PropTypes.string,
  'routes': PropTypes.arrayOf(PropTypes.object),
  'variant': PropTypes.string
}
SidebarResponsive.propTypes = {
  'routes': PropTypes.arrayOf(PropTypes.object)
}
Sidebar.propTypes = {
  'routes': PropTypes.arrayOf(PropTypes.object)
}


/*
 * EXPORTS
 */
export default Sidebar
export { SidebarResponsive }
