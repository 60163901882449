/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query MccReadSmsQuery($customerId: ID, $endDate: DateTime!, $startDate: DateTime!) {
  MccReadSms(customerId: $customerId, endDate: $endDate, startDate: $startDate) {
    id,
    status,
    message,
    _smsCountGroupedByCountryName
  }
}
`
