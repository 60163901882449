/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: Prop types.
import { connect } from 'react-redux' // Npm: Redux connect HOC.
import { Flex, useBreakpointValue } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import VendorMessageReport from 'pages/Reports/Message'
import VendorTrafficReport from 'components/VendorTrafficReport'
import VendorBillingReport from 'components/VendorBillingReport'


/*
 * OBJECTS
 */
const Index = ({ account }) => {
  // Const statement.
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _isCurrentViewTablet = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': false, 'xl': false, 'sm': false, 'xs': false })
  const _isCurrentViewLargeScreen = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': true, 'xl': false, 'sm': false, 'xs': false })

  // Return component.
  return (
    <Flex marginTop={_isCurrentViewMobile && !_isCurrentViewTablet ? '680px' : _isCurrentViewTablet && !_isCurrentViewLargeScreen ? '390px' : _isCurrentViewLargeScreen ? '420px' : '240px'} flexDir='column' w='100%' gap='22px' borderRadius='22px' pb='42px'>
      <Flex w='100%' gap='22px' flexDir={{ 'base': 'column', 'md': 'row' }}>
        <Flex flexDir='column' w={{ 'base': '100%', 'md': '50%' }} h='100%'>
          <VendorTrafficReport />
        </Flex>
        <Flex w={{ 'base': '100%', 'md': '50%' }}>
          <VendorBillingReport />
        </Flex>
      </Flex>
      <VendorMessageReport vendorId={_.first(account?.VendorLogin)?.id} />
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

