/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
    mutation AccountLoginWithEmailMutation($email: EmailAddress!, $password: String!) {
        AccountLoginWithEmail(email: $email, password: $password) {
            id,
            email,
            createdAt,
            displayName
            bio
            email
            website,
            accountType,
            thumbnailStoredAt,
            profileCoverThumbnailStoredAt,
            token,
            message,
            status,
            fullName,
            CustomerLogin {
                id
            }
            VendorLogin {
                id,
                displayName
            }
        }
    }
`
