/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import Moment from 'moment' // Npm: moment.js library.
import _ from 'underscore' // Npm: underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  useBreakpointValue,
  Thead,
  Tr
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import TableSpinner from 'components/TableSpinner'
import DownloadToExcel from 'components/DownloadToExcel'


/*
 * STYLES
 */
import { cellStyle, headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
const Index = ({ passOn }) => {
  // Hook assignment.
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Variable assignment.
  const _queryCustomerRead = passOn?.CreditHistory

  // Return component.
  return (
    <>
      <Flex className='reportsVendor base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px'>
            <Flex justifyContent='flex-end'>
              <DownloadToExcel
                cellsData={_.compact(_queryCustomerRead?.map((item, __index) => ({
                  'S.No.': `${__index + 1}.`,
                  'CreatedAt': Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A'),
                  'UpdatedAt': Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A'),
                  'Last Limit': item?.limit ?? '-',
                  'Last Balance': item?.balance ?? '-',
                  'Credited By': item?.creditedBy?.displayName ?? '-',
                  'Creditor Email': item?.creditedBy?.email ?? '-'
                })))}
                headersData={[
                  'S.No.',
                  'CreatedAt',
                  'UpdatedAt',
                  'Last Limit',
                  'Last Balance',
                  'Credited By',
                  'Creditor Email'
                ].map(i => ({ 'key': i, 'label': i }))} /></Flex>
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='md'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>S.No.</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>CreatedAt</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>UpdatedAt</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Last Limit</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Last Balance</Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>Credited By</Td>
                  <Td style={rowStyle}>Creditor Email</Td>
                </Tr>
              </Thead>
              {_.isEmpty(_queryCustomerRead) || 0 === _queryCustomerRead?.length ? (<TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />) : (
                <Tbody style={cellStyle}>
                  {_queryCustomerRead?.map((item, __index) => (
                    <Tr key={String.random(8)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {' '}
                        {`${__index + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.createdAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(item.updatedAt).format('YYYY-MM-DD hh:mm:ss A')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.limit ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.balance ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {item?.creditedBy?.displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {item?.creditedBy?.email ?? '-'}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Flex>
      </Flex>
    </>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'passOn': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)

