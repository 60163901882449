/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query ContactBookReadQuery($contactBookDirectoryId: ID!, $take: PositiveInt!, $skip: Int!) {
    ContactBookRead(contactBookDirectoryId: $contactBookDirectoryId, take: $take, skip: $skip) {
      id,
      contactBook,
      _totalCount,
      message,
      status
    }
  }
`
