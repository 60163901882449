/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
mutation DltDirectoryUpsertMutation($customerId: ID!, $dltDirectoryId: ID, $displayName: String!, $peId: ID!) {
  DltDirectoryUpsert(customerId: $customerId, dltDirectoryId: $dltDirectoryId, displayName: $displayName, peId: $peId) {
    id
    message
    status
  }
}
`
