/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query DltDirectoryReadUniqueQuery($dltDirectoryId: ID!) {
  DltDirectoryReadUnique(dltDirectoryId: $dltDirectoryId) {
    id,
    displayName,
    peId,
    message,
    status
  }
}
`
