/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: prop types.
import _ from 'underscore' // Npm: Underscore.js library.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import {
  Flex,
  Text,
  useBreakpointValue
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import ReportsMessage from 'pages/Reports/Message'
import SendMessage from 'components/SendMessage'
import MessagePieReport from 'components/MessagePieReport'
import SystemLoadChart from 'components/SystemLoadChart'
import CustomerTrafficReport from 'components/CustomerTrafficReport'
import CustomerCreditReport from 'components/CustomerCreditReport'
import CustomerActiveCreditStatus from 'components/CustomerActiveCreditStatus'


/*
 * EXPORTS
 */
const Index = ({ account }) => {
  // Const statement.
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })
  const _isCurrentViewTablet = useBreakpointValue({ 'base': 'false', 'md': true, 'lg': false, 'xl': false, 'sm': false, 'xs': false })
  const _isCurrentViewLargeScreen = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': true, 'xl': false, 'sm': false, 'xs': false })

  // Return statement.
  return (
    <Flex marginTop={_isCurrentViewMobile && !_isCurrentViewTablet ? '680px' : _isCurrentViewTablet && !_isCurrentViewLargeScreen ? '390px' : _isCurrentViewLargeScreen ? '420px' : '240px'} direction={{ 'base': 'column' }} fontFamily='DM Sans'>
      <Flex flexDir={_isCurrentViewMobile || _isCurrentViewTablet ? 'column' : 'row'} gap={_isCurrentViewMobile ? '12px' : '22px'} mb={_isCurrentViewMobile ? '12px' : '22px'}>
        <Flex w={_isCurrentViewLargeScreen ? '50%' : '100%'}>
          <SendMessage />
        </Flex>
        <Flex w={_isCurrentViewLargeScreen ? '50%' : '100%'} flexDir='column' gap='22px'>
          <CustomerActiveCreditStatus />
          <SystemLoadChart />
          <MessagePieReport />
          <CustomerTrafficReport />
          <CustomerCreditReport />
        </Flex>
      </Flex>
      <Flex w='100%' flexDir='column'>
        <Text
          textColor='#2B3674'
          fontWeight='bold'
          fontFamily='Poppins'
          fontSize={['clamp(20px, 3vw, 40px)']}
          lineHeight='42px'
          letterSpacing='0.48px'
          py='20px'
          pl='20px'>
          Monitoring
        </Text>
      </Flex>
      <ReportsMessage customerId={_.first(account?.CustomerLogin)?.id} />
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
