/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query CustomerAccountReadQuery($customerAccountId: ID!) {
  CustomerAccountRead(customerAccountId: $customerAccountId) {
    id,
    createdAt,
    displayName,
    enableLossProtection,
    message,
    status,
    _totalCount,
    Rate{
      displayName,
    },
    Route{
      displayName,
    },
    Smpp {
        id,
        displayName,
        ip,
        rxPort,
        txPort,
        username,
        password,
        sessionTimeoutInterval,
        enquireLinkInterval,
        trunkType,
        sessionAllowed,
        tps,
        debuggingLevel,
        message,
        status
    }
  }
}
`
