/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.

/*
 * EXPORTS
 */
export default gql`
  query DltDirectoryReadQuery($customerId: ID!, $take: PositiveInt!, $skip: Int!) {
    DltDirectoryRead(customerId: $customerId, take: $take, skip: $skip) {
      id,
      displayName,
      message,
      status,
      peId,
      Dlt {
        id,
        displayName,
        senderId,
        content,
        templateId
      }
    }
  }
`
