/*
 * IMPORTS
 */
import React from 'react' // Npm: React.js library.
import PropTypes from 'prop-types' // Npm: React.js library.
import _ from 'underscore' // Npm: Utility module.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useQuery } from '@apollo/client' // Npm: Apollo client.
import { HiDocumentText } from 'react-icons/hi2' // Npm: React icons.
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useStyleConfig
} from '@chakra-ui/react' // Npm: A simple, modular and accessible component library for React.js.


/*
 * PACKAGES
 */
import DateRangePicker from 'components/DateRangePicker'
import TableSpinner from 'components/TableSpinner'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import MccReadSmsQuery from './__query__/index.mcc.readSms.query'
import VendorAccountReadQuery from './__query__/index.vendorAccount.read.query'


/*
 * STYLES
 */
import { headerStyle, rowStyle } from './index.style'


/*
 * OBJECTS
 */
function Index({ title, account }) {
  // Hook assignment.
  const [dateRange, setDateRange] = React.useState({
    'selection': {
      'startDate': Object.DateRangeProvider()?.startDate,
      'endDate': Object.DateRangeProvider()?.endDate,
      'key': 'selection'
    }
  })
  const [smsRead, setSmsRead] = React.useState([])
  const [vendorAccount, setVendorAccount] = React.useState(void 0)
  const [QueryMccReadSms, QueryMccReadSmsResponse] = useLazyQuery(MccReadSmsQuery, {
    'variables': {
      'vendorId': _.first(account?.VendorLogin)?.id,
      'startDate': dateRange?.selection.startDate,
      'endDate': dateRange?.selection.endDate
    },
    'fetchPolicy': Object.React.App.fetchPolicy,
    'pollInterval': Object.React.App.pollInterval
  })
  const _QueryVendorAccountRead = useQuery(VendorAccountReadQuery, { 'variables': { 'vendorId': _.first(account?.VendorLogin)?.id }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _styles = useStyleConfig('Card')
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Event handler
  React.useEffect(() => {
    // Async handler.
    const _Async = async () => {
      // Local variable.
      let _data

      // Refetch data on date range change.
      const { data, error } = await QueryMccReadSms({ 'variables': { 'vendorId': _.first(account?.VendorLogin)?.id, 'startDate': dateRange.selection.startDate, 'endDate': dateRange.selection.endDate } })

      // If getting data caught exception then report failure.
      if (error instanceof Error) throw error

      // If given account login is TERMINATION
      // then filter data by vendor account.
      if (account && 'TERMINATION' === account?.accountType) {
        // Assign data.
        _data = data?.MccReadSms?._smsCountBasedOnVendorAccountCost[_.first(account?.VendorLogin)?.id]
      } else {
        // Filter data if vendor account is selected.
        if (vendorAccount) {
          // Filter data.
          _data = data?.MccReadSms?._smsCountBasedOnVendorAccountCost?.[vendorAccount]
        } else {
          // Assign data.
          _data = {}

          // If data is available then map it.
          data?.MccReadSms?._smsCountBasedOnVendorAccountCost && _.compact(Object.entries(_.values(data?.MccReadSms?._smsCountBasedOnVendorAccountCost ?? {}) ?? {}))?.map(e => Object.assign(_data, e[1]))
        }
      }

      // Update state.
      setSmsRead(Object.entries(_data ?? {}))
    }; _Async().catch(i => { throw i })
  }, [dateRange, vendorAccount])

  // Return component.
  return (
    <Flex
      __css={_styles}
      align='center'
      display='flex'
      direction='column'
      w='100%'
      p={_isCurrentViewMobile ? '12px' : '22px'}
      gap={_isCurrentViewMobile ? '12px' : '22px'}
      boxShadow='14px 17px 40px 4px rgba(112, 144, 176, 0.17)'>
      <Flex justify='space-between' alignItems='center' width='100%'>
        <Flex flexDir='column' align='center' justifyContent='center' gap='12px'>
          <Flex gap='6px' color='brand.500'>
            <HiDocumentText size={21} />
            <Text fontSize='lg' color='brand.500' textAlign='left'>
              {title ?? 'Billing Report'}
            </Text>
          </Flex>
          <DateRangePicker
            bg='purple.50'
            color='purple'
            startDate={dateRange?.selection?.startDate}
            endDate={dateRange?.selection?.endDate}
            setSelectDate={setDateRange}
            isLoading={QueryMccReadSmsResponse?.loading} />
        </Flex>
        <Flex gap='12px' flexDir='row' flexWrap='wrap'>
          <MemoizedSelect
            name='vendorId'
            placeholder='Select Vendor Account'
            w='210px'
            bg='purple.50'
            color='purple'
            data={_.first(account?.VendorLogin)?.id}
            options={(_QueryVendorAccountRead?.data?.VendorAccountRead ?? [])?.map(j => j.displayName ? `${j.displayName} (${j.id})` : void 0)}
            onChange={e => setVendorAccount(e.target?.value?.split('(')?.[1]?.split(')')?.[0])}
          />
        </Flex>
      </Flex>
      <TableContainer
        flex={1}
        display='flex'
        borderRadius='15px'
        outline='1px solid #C5CFE8'>
        <Table variant='simple' size='md'>
          <Thead bg='rgba(244, 247, 254, 1)'>
            <Tr>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                S.No.
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Country Name
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Total Cost
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Sms Count
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Parts Count
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Delivered Cost
              </Th>
              <Th
                style={headerStyle}
                borderRight='1px solid rgba(216, 227, 252, 1)'>
                Failed Cost
              </Th>
              <Th style={headerStyle}>
                Pending Cost
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              QueryMccReadSmsResponse.loading ? (
                <TableSpinner isLoading={true} />
              ) : 0 === smsRead?.length ? (
                <TableSpinner isLoading={false} isEmpty={true} />
              ) : smsRead.map((sms, index) => _.isEmpty(sms) ? void 0 : (
                <Tr key={String.random(8)}>
                  <Td style={rowStyle} isNumeric borderRight='1px solid rgba(216,227,252,1)'>
                    {index + 1}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[0] ?? '-'}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?._totalCost?.toFixed(2) ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?._totalCount ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?._totalParts ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?._totalDeliveredCost?.toFixed(2) ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center' borderRight='1px solid rgba(216,227,252,1)'>
                    {sms?.[1]?._totalFailedCost?.toFixed(2) ?? 0}
                  </Td>
                  <Td style={rowStyle} textAlign='center'>
                    {sms?.[1]?._totalPendingCost?.toFixed(2) ?? 0}
                  </Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'account': PropTypes.object.isRequired,
  'title': PropTypes.string
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'account': __state.Account })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
