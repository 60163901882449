/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import PropTypes from 'prop-types' // Npm: react.js library.
import JoiBrowser from 'joi-browser' // Npm: Joi for frontend validation.
import _ from 'underscore' // Npm: Underscore.js library.
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { connect } from 'react-redux' // Npm: React Redux for state management.
import { useLazyQuery, useMutation } from '@apollo/client' // Npm: Apollo client.
import { Flex } from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import SubmitButton from 'components/SubmitButton'
import MccMncSelector from 'components/MccMncSelector'
import { MemoizedSelect } from 'components/MemoizedInput'


/*
 * GRAPHS
 */
import RoutePlanReadQuery from './__query__/index.routePlan.read.query'
import RoutePlanUpdateMutation from './__mutation__/index.routePlan.update.mutation'
import RoutePlanCreateMutation from './__mutation__/index.routePlan.create.mutation'
import RoutePlanDeleteManyMutation from './__mutation__/index.routePlan.deleteMany.mutation'
import VendorAccountAttachRoutePlanMutation from './__mutation__/index.vendorAccount.attachRoutePlan.mutation'


/*
 * OBJECTS
 */
const Index = ({ route, isOpen, isCreateOnly, onClose, passOn }) => {
  // Hook assignment.
  const [MutationVendorAccountAttachRoutePlan, MutationVendorAccountAttachRoutePlanResponse] = useMutation(VendorAccountAttachRoutePlanMutation)
  const [error, setError] = React.useState('')
  const [forceReRender, setForceReRender] = React.useState('')
  const [QueryRoutePlanRead, QueryRoutePlanReadResponse] = useLazyQuery(RoutePlanReadQuery, { 'variables': { 'routePlanId': passOn?.routePlanId } })
  const [MutationRoutePlanCreate, MutationRoutePlanCreateResponse] = useMutation(RoutePlanCreateMutation)
  const [MutationRoutePlanUpdate, MutationRoutePlanUpdateResponse] = useMutation(RoutePlanUpdateMutation)
  const [MutationRoutePlanDeleteMany, MutationRoutePlanDeleteManyResponse] = useMutation(RoutePlanDeleteManyMutation)
  const _formDataRef = React.useRef({})

  // Object assignment.
  const _AttachVendorAccountToRoutePlan = async (__id, __raw) => {
    // Const assignment.
    const _executionStatus = []

    // Check __raw with given __id
    if (!_.isArray(__raw)) return toast('Route Plan is missing, Kindly delete and create a new one.')
    if (!_.isArray(__id)) return toast('Route Plan is missing, Kindly delete and create a new one.')

    // Loop over __id and __raw to find the correct routePlanId.
    for (const __routePlanId of __id) {
      // Const assignment.
      const _filteredRaw = __raw?.filter(i => i?.id === __routePlanId)?.[0]

      // If filteredRaw is empty then report failure.
      if (_.isEmpty(_filteredRaw)) return toast('Route Plan is missing, Kindly delete and create a new one.')

      // Filter out vendor account.
      const _filteredVendorAccount = _.first(route)?.RoutePlan?.filter(j => j?.Mnc?.mnc === _filteredRaw?.Mnc?.mnc)?.[0]?.VendorAccount ?? []

      // If filteredVendorAccount is empty then report failure.
      if (_.isEmpty(_filteredVendorAccount)) return MutationRoutePlanDeleteMany({ 'variables': { 'routePlanIds': [__routePlanId] } })

      // Execute mutation.
      const _MutationVendorAccountAttachRoutePlan = await MutationVendorAccountAttachRoutePlan({
        'variables': {
          'routePlanId': [__routePlanId],
          'vendorAccountId': _filteredVendorAccount?.id
        }
      })

      // If mutation caught an exception then report failure.
      if (_MutationVendorAccountAttachRoutePlan instanceof Error) return _MutationVendorAccountAttachRoutePlan

      // Style Guide.
      toast(_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i) ? 'Successfully attached the vendor with routePlan' : 'Something went wrong. Please try again after some time.')

      // On successful bind close modal.
      if (_.every(_.pluck(_MutationVendorAccountAttachRoutePlan?.data?.VendorAccountAttachRoutePlan, 'status'), i => 'UPDATE_SUCCESSFUL' === i)) _executionStatus.push(true)
      else _executionStatus.push(false)
    }

    // Report void.
    return _.every(_executionStatus, i => i) ? onClose() : toast('Something went wrong. Please try again after some time.')
  }
  const _SubmitForm = async e => {
    // Local variable.
    let _routePlanIds

    // Prevent default behavior.
    e.preventDefault()

    // If Route doesn't exists or Route has no route plan
    // then report failure.
    if (_.isEmpty(route) || _.isEmpty(_.first(route)?.RoutePlan)) return toast('Route Plan is missing, add route plan of your account before adding it for child.')

    // Reset error.
    setError('')

    // Const assignment.
    const _JoiSchema = JoiBrowser.object({
      'routeToVendorAccount': JoiBrowser.string(),
      'priority': JoiBrowser.number().greater(0).less(4),
      'smsType': ['RESELLER', 'RETAIL'].includes(passOn.type) ? JoiBrowser.string().required() : JoiBrowser.string().optional(),
      'mcc': JoiBrowser.string().required(),
      'mnc': JoiBrowser.array().items(JoiBrowser.string().required()).required(),
      'senderIdDirectoryId': JoiBrowser.string(),
      'senderId': JoiBrowser.array().items(JoiBrowser.string()),
      'phoneNumberoDirectory': JoiBrowser.string(),
      'phoneNumbero': JoiBrowser.array().items(JoiBrowser.string()),
      'fakeDlrRate': JoiBrowser.number()
    }).options({ 'allowUnknown': true })

    // Make sure that mnc is array.
    if (!isCreateOnly && !_.isArray(_formDataRef.current?.mnc)) _formDataRef.current = { ..._formDataRef.current, 'mnc': [(_formDataRef.current?.mnc)?.split('(')[1]?.split(')')[0]] }

    /*
     * Report failure if leastCostRoute is off and vendorAccount
     * priority is not set.
     */
    if ((0 >= _formDataRef.current?.priority || isNaN(_formDataRef.current?.priority))) return setError('priority is required if you are not using Least Cost Routing.')

    // Remove all keys from _formDataRef.current which are undefined.
    _formDataRef.current = { ..._.pick(_formDataRef.current, _.identity), 'fakeDlrRate': _formDataRef.current?.fakeDlrRate ?? 0 }

    // Validate form data.
    const _JoiSchemaValidate = _JoiSchema.validate(_formDataRef.current)

    // If error exists then report failure.
    if (_JoiSchemaValidate.error) return setError(_JoiSchemaValidate.error?.message)

    /*
     * Update _formDataRef with routePlanId
     * if it doesn't exist and also make sure that mode
     * is not create only.
     */
    if (!isCreateOnly && _.isEmpty(_formDataRef.current?.routePlanId)) _formDataRef.current = { ..._formDataRef.current, 'routePlanId': passOn?.routePlanId }
    if (isCreateOnly && _.isEmpty(_formDataRef.current?.routeId)) _formDataRef.current = { ..._formDataRef.current, 'routeId': passOn?.routeId }

    /*
     * Update _formDataRef with input and routePlan
     * if it doesn't exist. Also make sure that mode is
     * not create only.
     */
    if (_.isEmpty(_formDataRef.current?.input)) _formDataRef.current = ({ 'input': [_formDataRef.current], ..._formDataRef.current })

    // Execute update mutation.
    const _MutationRoutePlanUpdate = await [isCreateOnly ? MutationRoutePlanCreate : MutationRoutePlanUpdate]?.[0]({
      'variables': {
        'routePlanId': _.isEmpty(passOn?.routePlanIds) ? isCreateOnly ? '' : passOn?.routePlanId : void 0,
        'routePlanIds': _.isEmpty(passOn?.routePlanIds) ? void 0 : isCreateOnly ? '' : passOn?.routePlanIds,
        'input': _.pick(_formDataRef.current, 'input').input.map(j => {
          // Const assignment.
          let _mcc, _mnc

          // Variable assignment.
          _mcc = j?.mcc
          _mnc = j?.mnc

          // Conditionally update senderId and senderIdDirectoryId.
          if (j?.mcc && (j?.mcc?.includes('(') || j?.mcc?.includes(')'))) _mcc = j.mcc.split('(')[1].split(')')[0]
          if (j?.mnc && 0 < j?.mnc?.length) _mnc = _.compact(_.flatten(j?.mnc.map(r => r?.includes('(') && r?.includes(')') ? r.split('(')[1].split(')')[0] : r)))

          // Return data.
          return ({
            'fakeDlrRate': j?.fakeDlrRate,
            'mcc': _mcc,
            'mnc': _mnc,
            'priority': j?.priority,
            'smsType': j?.smsType,
            ...(isCreateOnly ? { 'routeId': passOn?.routeId } : {})
          })
        })
      }
    })

    // If mutation caught an exception then report failure.
    if (_MutationRoutePlanUpdate instanceof Error) return _MutationRoutePlanUpdate

    // If Update contains data then update routePlanIds.
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate)) _routePlanIds = _.compact(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'id'))
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate)) _routePlanIds = _.compact(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'id'))

    // Attach vendor account and route to customer.
    !_.isEmpty(_routePlanIds) && _.isArray(_routePlanIds) && await _AttachVendorAccountToRoutePlan(_routePlanIds, _MutationRoutePlanUpdate?.data?.RoutePlanCreate ?? _MutationRoutePlanUpdate?.data?.RoutePlanUpdate)

    // Check if create was successful.
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate) && _.every(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'status') ?? [], j => 'CREATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully upserted the route plan.')

      // On successful create close modal.
      return onClose?.()
    }
    if (!_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate) && _.every(_.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'status') ?? [], j => 'UPDATE_SUCCESSFUL' === j)) {
      // Style guide.
      toast('Successfully upserted the route plan.')

      // Report failure.
      return onClose?.()
    }

    // Style Guide.
    toast((_.isEmpty(_MutationRoutePlanUpdate?.data?.RoutePlanCreate) ? [0 === _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'message')?.length ? 'Mcc, Mnc Conflict found, kindly make sure that you are adding unique mcc and mnc.' : _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanUpdate, 'message')]?.join(' ') : _.pluck(_MutationRoutePlanUpdate?.data?.RoutePlanCreate, 'message')?.join(' ')))

    // Report void.
    return void 0
  }

  // Event handler.
  React.useEffect(() => {
    // _Async handler.
    const _Async = async () => {
      // Const assignment.
      const _QueryRoutePlanReadQuery = await QueryRoutePlanRead({ 'variables': { 'routePlanId': isCreateOnly ? 'UN_KNOWN' : passOn?.routePlanId } })

      // If query caught an exception then report failure.
      if (_QueryRoutePlanReadQuery instanceof Error) return _QueryRoutePlanReadQuery

      /*
       * If routePlan details fetch complete then
       * update its value.
       */
      if (_QueryRoutePlanReadQuery?.data?.RoutePlanRead) {
        // Const assignment.
        const _routePlanRead = _.first(_QueryRoutePlanReadQuery?.data?.RoutePlanRead)

        // Update form data.
        _formDataRef.current = {
          'routePlanId': passOn?.routePlanId,
          'createdAt': _routePlanRead?.createdAt,
          'updatedAt': _routePlanRead?.updatedAt,
          'mcc': _routePlanRead?.Mcc?.mcc ? `${_routePlanRead?.Mcc?.countryName} (${_routePlanRead?.Mcc?.mcc})` : void 0,
          'mnc': _routePlanRead?.Mnc?.mnc ? `(${_routePlanRead?.Mnc?.mnc}) ${_routePlanRead?.Mnc?.network}` : void 0,
          'countryName': _routePlanRead?.Mcc?.countryName,
          'priority': _routePlanRead?.priority,
          'isPaused': _routePlanRead?.isPaused,
          'smsType': _routePlanRead?.smsType,
          'fakeDlrRate': _routePlanRead?.fakeDlrRate,
        }

        // Update state.
        return setForceReRender(String.random(8))
      }

      // Report failure.
      return void 0
    }; _Async().catch(i => i)
  }, [passOn, isOpen])

  // Const assignment.
  const _isLoading = MutationVendorAccountAttachRoutePlanResponse.loading || MutationRoutePlanUpdateResponse.loading || MutationRoutePlanCreateResponse.loading || MutationRoutePlanDeleteManyResponse.loading
  const _isInputDisabled = isCreateOnly ? false : _isLoading || QueryRoutePlanReadResponse.loading

  // Return component.
  return (
    <form style={{ 'width': '100%' }} onSubmit={_SubmitForm} className='routePlanUpsert' key={forceReRender}>
      <Flex gap='22px' flexDir='column' wrap='nowrap' w='100%'>
        <Flex width='100%' gap='18px' direction={{ 'base': 'column', 'md': 'row' }}>
          <MemoizedSelect
            disabled={_isInputDisabled}
            name='priority'
            label='Priority'
            placeholder='Enter Priority'
            onChange={({ target }) => {
              // Over spreading.
              const { name, value } = target

              // Update form data.
              _formDataRef.current = {
                ..._formDataRef?.current,
                [name]: isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10)
              }
            }}
            error={error}
            isInvalid={error?.includes('priority')}
            data={_formDataRef?.current?.priority}
            options={[1, 2, 3]}
            isRequired
          />
          {['RESELLER', 'RETAIL'].includes(passOn.type) ? (
            <MemoizedSelect
              disabled={_isInputDisabled}
              name='smsType'
              label='SMS Type'
              placeholder='Enter SMS Type'
              onChange={({ target }) => {
                // Over spreading.
                const { name, value } = target

                // Update form data.
                _formDataRef.current = {
                  ..._formDataRef?.current,
                  [name]: value
                }
              }}
              error={error}
              isRequired={true}
              isInvalid={error?.includes('smsType')}
              data={_formDataRef?.current?.smsType}
              options={Object.React.App.enums.SMS_TYPE.enums?.map(i => i.key)}
            />
          ) : void 0}
        </Flex>
        <MccMncSelector
          isRequired={true}
          disabled={_isInputDisabled}
          mccValue={_formDataRef?.current?.mcc}
          mncValue={_formDataRef?.current?.mnc}
          inValidMnc={error?.includes('mnc')}
          inValidMcc={error?.includes('mcc')}
          onChange={i => {
            // Update form data.
            _formDataRef.current = ({ ..._formDataRef.current, 'mnc': i.mnc, 'mcc': i.mcc })

            // Force re-render.
            setForceReRender(String.random(8))
          }}
        />
        <MemoizedSelect
          key={forceReRender}
          disabled={_isInputDisabled}
          name='fakeDlrRate'
          label='Fake Dlr Rate (in Percentage)'
          placeholder='e.g. "10 is 10%"'
          onChange={({ target }) => {
            // Over spreading.
            const { name, value } = target

            // Update form data.
            _formDataRef.current = {
              ..._formDataRef?.current,
              [name]: isNaN(parseFloat(value, 10)) ? 0 : parseFloat(value, 10)
            }
          }}
          error={error}
          isInvalid={error?.includes('fakeDlrRate')}
          data={_formDataRef?.current?.fakeDlrRate}
          options={[
            0,
            10,
            20,
            30,
            40,
            50,
            60,
            70,
            80,
            90,
            100
          ]}
        />
      </Flex>
      <SubmitButton
        isLoading={_isLoading}
        defaultText={isCreateOnly ? 'Create Route Plan' : 'Update Route Plan'}
        disabled={_isInputDisabled}
        onSubmit={_SubmitForm}
      />
    </form>
  )
}


/*
 * PROPTYPES
 */
Index.propTypes = {
  'isCreateOnly': PropTypes.bool,
  'isOpen': PropTypes.bool,
  'onClose': PropTypes.func,
  'passOn': PropTypes.object,
  'submitRef': PropTypes.object,
  'disabled': PropTypes.bool,
  'isUpsertMixerContext': PropTypes.bool,
  'setSubmitIsLoading': PropTypes.func,
  'route': PropTypes.object.isRequired
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'passOn': __state.PassOn, 'route': __state.Route })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
