/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: Apollo client for handling graphql request.


/*
 * EXPORTS
 */
export default gql`
query DltReadUniqueQuery($dltId: ID!) {
  DltReadUnique(dltId: $dltId) {
    id,
    displayName,
    senderId,
    content,
    templateId,
    message,
    status
  }
}
`
