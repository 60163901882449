/*
 * IMPORTS
 */
import React from 'react' // Npm: react.js library.
import Moment from 'moment' // Npm: Moment library.
import Debounce from 'lodash/debounce' // Npm: lodash library.
import _ from 'underscore' // Npm: underscore library.
import { useQuery } from '@apollo/client' // Npm: Apollo client.
import { IoMdAdd } from 'react-icons/io'
import { connect } from 'react-redux' // Npm: React Redux for state management.
import PropTypes from 'prop-types' // Npm: react.js library.
import {
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  useBreakpointValue,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react' // Npm: Chakra UI components.


/*
 * PACKAGES
 */
import DownloadToExcel from 'components/DownloadToExcel'
import PaginationWithPageInformation from 'components/PaginationWithPageInformation'
import TableSpinner from 'components/TableSpinner'
import Modal from 'components/Modal'
import DltDirectoryUpsert from 'components/DltDirectoryUpsert'
import DltDirectoryDropDownOptions from 'components/DltDirectoryDropDownOptions'
import DltDirectoryDelete from 'components/DltDirectoryDelete'


/*
 * STYLES
 */
import {
  buttonStyle,
  cellStyle,
  headerStyle,
  rowStyle
} from './index.style'


/*
 * GRAPHS
 */
import DltDirectoryReadQuery from './__query__/index.dltDirectory.read.query'


/*
 * OBJECTS
 */
const Index = ({ customer }) => {
  // Const assignment.
  const _skipDifference = 8
  const _successFlags = Object.React.App.enums.GRAPHQL_SUCCESSFUL_QUERY_FLAGS.enums.map(i => i.key)

  // Hook assignment.
  const [skipPage, setSkipPage] = React.useState(0)
  const [skipDifference, setSkipDifference] = React.useState(_skipDifference)
  const [dltDirectoryToSearch, setDltDirectoryToSearch] = React.useState('')
  const { 'isOpen': isDltDirectoryCreateOpen, 'onOpen': onDltDirectoryCreateOpen, 'onClose': onDltDirectoryCreateClose } = useDisclosure()
  const { 'isOpen': isDltDirectoryUpdateOpen, 'onOpen': onDltDirectoryUpdateOpen, 'onClose': onDltDirectoryUpdateClose } = useDisclosure()
  const { 'isOpen': isDltDirectoryDeleteOpen, 'onOpen': onDltDirectoryDeleteOpen, 'onClose': onDltDirectoryDeleteClose } = useDisclosure()
  const _QueryDltDirectoryRead = useQuery(DltDirectoryReadQuery, { 'variables': { 'customerId': customer?.id, dltDirectoryToSearch, 'skip': skipPage * skipDifference, 'take': skipDifference }, 'fetchPolicy': Object.React.App.fetchPolicy, 'pollInterval': Object.React.App.pollInterval })
  const _isFirstLoadCompleted = React.useRef(false)
  const _onDltDirectoryToSearchInputChange = React.useCallback(Debounce(e => setDltDirectoryToSearch(e.target.value), 800), [])
  const _tableHeaderHeightRef = React.useRef(0)
  const _isCurrentViewMobile = useBreakpointValue({ 'base': 'false', 'md': false, 'lg': false, 'xl': false, 'sm': true, 'xs': true })

  // Data loading check.
  if (!_QueryDltDirectoryRead.loading && 0 < _QueryDltDirectoryRead.data?.DltDirectory?.length && !_isFirstLoadCompleted.current) _isFirstLoadCompleted.current = true
  if (!_.every(_.pluck(_QueryDltDirectoryRead.data?.DltDirectoryRead, 'status'), i => _successFlags.includes(i))) _isFirstLoadCompleted.current = false

  // Return component.
  return (
    <>
      <Flex className='dltDirectory base'>
        <Flex
          display='flex'
          flex={1}
          flexDirection='column'
          gap={_isCurrentViewMobile ? '12px' : '22px'}
          bg='white'
          height='100%'
          borderRadius='20px'
          p={_isCurrentViewMobile ? '12px' : '22px'}>
          <Flex pb='0px' justify='space-between'>
            <Flex gap='12px'>
              <Tooltip label='Add new dlt.' fontSize='sm'>
                <Button
                  leftIcon={<IoMdAdd />}
                  onClick={onDltDirectoryCreateOpen}
                  style={buttonStyle}>
                  Add New Entity
                </Button>
              </Tooltip>
            </Flex>
            <DownloadToExcel
              cellsData={0 === _QueryDltDirectoryRead?.data?.DltDirectoryRead?.length || !_.every(_.pluck(_QueryDltDirectoryRead?.data?.DltDirectoryRead, 'status'), i => _successFlags.includes(i)) ? [] : _QueryDltDirectoryRead?.data?.DltDirectoryRead.map((item, __index) => ({
                'S.No.': `${(__index + (skipDifference * (skipPage))) + 1}.`,
                'Entity Name': item.displayName ?? '-',
                'Total Templates': item?.Dlt?.length ?? '-',
                'CreatedAt': Moment(item?.createdAt).format('YYYY-MM-DD'),
                'UpdatedAt': Moment(item?.updatedAt).format('YYYY-MM-DD')
              }))}
              headersData={[
                'S.No.',
                'Entity Name',
                'Total Templates',
                'CreatedAt',
                'UpdatedAt'
              ].map(i => ({ 'key': i, 'label': i }))} />
          </Flex>
          <TableContainer
            flex={1}
            display='flex'
            borderRadius='15px'
            outline='1px solid #C5CFE8'>
            <Table variant='simple' size='sm'>
              <Thead ref={_tableHeaderHeightRef}>
                <Tr style={{ ...headerStyle, 'position': 'sticky', 'top': 0, 'zIndex': 1 }}>
                  <Td
                    style={rowStyle}
                    py='4'
                    borderRight='1px solid rgba(216, 227, 252, 1)'>
                    S.No
                  </Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Entity Name</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Total Templates</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Created At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Updated At</Td>
                  <Td
                    style={rowStyle}
                    borderRight='1px solid rgba(216, 227, 252, 1)'>Actions</Td>
                </Tr>
                <Tr >
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td style={rowStyle} borderRight='1px solid rgba(216, 227, 252, 1)'>
                    <Input
                      placeholder='Search'
                      className='filter searchInput'
                      bg='gray.100'
                      defaultValue={dltDirectoryToSearch}
                      onChange={_onDltDirectoryToSearchInputChange}
                    />
                  </Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                  <Td borderRight='1px solid rgba(216, 227, 252, 1)'></Td>
                </Tr>
              </Thead>
              {_QueryDltDirectoryRead.loading && !_isFirstLoadCompleted.current ? (
                <TableSpinner isLoading={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : !_isFirstLoadCompleted.current && (0 === _QueryDltDirectoryRead.data?.DltDirectoryRead?.length || !_.every(_.pluck(_QueryDltDirectoryRead.data?.DltDirectoryRead, 'status'), i => _successFlags.includes(i))) ? (
                <TableSpinner isLoading={false} isEmpty={true} chopHeightFromHundredPercentage={_tableHeaderHeightRef?.current?.clientHeight} />
              ) : (
                <Tbody style={cellStyle}>
                  {_QueryDltDirectoryRead.data?.DltDirectoryRead?.map(({ id, createdAt, updatedAt, displayName, Dlt }, __index) => (
                    <Tr key={String.random(9)}>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {`${(__index + (skipDifference * (skipPage))) + 1}.`}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {displayName ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Dlt?.length ?? '-'}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(createdAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}
                        borderRight='1px solid rgba(216, 227, 252, 1)'>
                        {Moment(updatedAt).format('YYYY-MM-DD')}
                      </Td>
                      <Td
                        style={rowStyle}>
                        {' '}
                        {
                          <DltDirectoryDropDownOptions
                            dltDirectoryId={id}
                            onDltDirectoryUpdate={onDltDirectoryUpdateOpen}
                            onDltDirectoryDelete={onDltDirectoryDeleteOpen}
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              )
              }
            </Table>
          </TableContainer>
        </Flex>
        <Modal
          size='md'
          title='Dlt Create'
          isOpen={isDltDirectoryCreateOpen}
          onClose={onDltDirectoryCreateClose}>
          <DltDirectoryUpsert isCreateOnly={true} />
        </Modal>
        <Modal
          size='md'
          title='Dlt Update'
          isOpen={isDltDirectoryUpdateOpen}
          onClose={onDltDirectoryUpdateClose}>
          <DltDirectoryUpsert />
        </Modal>
        <Modal
          size='md'
          title='Dlt Delete'
          isOpen={isDltDirectoryDeleteOpen}
          onClose={onDltDirectoryDeleteClose}>
          <DltDirectoryDelete />
        </Modal>
      </Flex>
      <PaginationWithPageInformation
        skipPage={skipPage}
        skipDifference={_skipDifference}
        setSkipDifference={setSkipDifference}
        itemsPerPage={_skipDifference}
        totalCount={_.pluck(_QueryDltDirectoryRead?.data?.DltDirectoryRead, '_totalCount')?.[0]}
        onPageChange={setSkipPage}
      />
    </>
  )
}


/*
 * EXPORT
 */
Index.propTypes = {
  'customer': PropTypes.object
}


/*
 * REDUX
 */
const _MapStateToProps = __state => ({ 'customer': __state.Customer })


/*
 * EXPORT
 */
export default connect(_MapStateToProps)(Index)
