/*
 * IMPORTS
 */
import { gql } from '@apollo/client' // Npm: apollo client.


/*
 * EXPORTS
 */
export default gql`
    query CreditReadQuery($customerId: ID!, $startDate: DateTime!, $endDate: DateTime!) {
        CreditRead(customerId: $customerId, startDate: $startDate, endDate: $endDate) {
            message,
            status,
            limit,
            balance,
            createdAt,
            History {
              createdAt,
              creditedBy,
              limit,
              balance
            },
            _objectOnRuntimeMemory
        }
    }
`


